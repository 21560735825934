import { useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftInput from "src/components/SoftInput";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { format } from "date-fns";
import PersonIcon from "@mui/icons-material/Person";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { getAircraftSquawksCollection } from "src/features/aircraft/collections";
import { useAircraft } from "src/hooks/useAircraft";
import SoftSelect from "src/components/SoftSelect";

const schema = yup
  .object({
    description: yup
      .string()
      .required("Please provide a description of the issue."),
  })
  .required();

export default function AddSquawkModal({ handleClose, aircraft }) {
  const { user, userId } = useUser();
  const [selectedAircraft, setSelectedAircraft] = useState();
  const { aircrafts } = useAircraft();
  const { selectedClubId, selectedLocationId } = useClubs();
  const { addData } = entityCrudUtils();
  const [grounded, setGrounded] = useState(false);
  const handleSetGrounded = () => setGrounded(!grounded);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const getAircraft = () => aircraft ?? selectedAircraft;

  const onSubmit = async (data) => {
    if (data.expiresType) data.expiresType = data.expiresType.value;

    const squawk = {
      aircraftId: getAircraft().id,
      tailNumber:
        getAircraft()?.aircraft_description?.tail_number ??
        getAircraft()?.tailNumber,
      reporter: { id: userId, displayName: user.displayName },
      date: new Date(),
      grounded,
      description: data.description,
      locationId: selectedLocationId,
      clubId: selectedClubId,
      resolved: false,
      status: data.status || "Unverified",
    };

    await addData(
      getAircraftSquawksCollection(selectedClubId, getAircraft().id),
      squawk
    );
    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Add Squawk
          </SoftTypography>
          <SoftBox mt={3}>
            <Grid container spacing={1} justifyContent="space-around">
              <Grid item xs={4}>
                <SoftBox>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox
                      display="grid"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="lg"
                      mb={1}
                    >
                      <PersonIcon
                        sx={{
                          display: "grid",
                          placeItems: "center",
                        }}
                        color="dark"
                        fontSize="large"
                      />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography
                        variant="button"
                        color="dark"
                        fontWeight="medium"
                        textAlign="center"
                        gutterBottom
                      >
                        Reporter
                      </SoftTypography>
                      <SoftTypography
                        variant="caption"
                        textAlign="center"
                        color="text"
                      >
                        {user.displayName}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
              {getAircraft() && (
                <Grid item xs={4}>
                  <SoftBox>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <SoftBox
                        display="grid"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="lg"
                        mb={1}
                      >
                        <AirplanemodeActiveIcon
                          sx={{
                            display: "grid",
                            placeItems: "center",
                          }}
                          color="dark"
                          fontSize="large"
                        />
                      </SoftBox>

                      <SoftBox display="flex" flexDirection="column">
                        <SoftTypography
                          variant="button"
                          color="dark"
                          fontWeight="medium"
                          textAlign="center"
                          gutterBottom
                        >
                          Aircraft
                        </SoftTypography>
                        <SoftTypography
                          variant="caption"
                          textAlign="center"
                          color="text"
                        >
                          {(getAircraft()?.aircraft_description?.tail_number ??
                            getAircraft()?.tailNumber) ||
                            "-"}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              )}
              <Grid item xs={4}>
                <SoftBox>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox
                      display="grid"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="lg"
                      mb={1}
                    >
                      <PendingActionsIcon
                        sx={{
                          display: "grid",
                          placeItems: "center",
                        }}
                        color="dark"
                        fontSize="large"
                      />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography
                        variant="button"
                        color="dark"
                        fontWeight="medium"
                        textAlign="center"
                        gutterBottom
                      >
                        Created
                      </SoftTypography>
                      <SoftTypography
                        variant="caption"
                        textAlign="center"
                        color="text"
                      >
                        {format(new Date(), "Pp")}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
              {!getAircraft() && (
                <Grid item xs={12}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                    width="100%"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Select Squawk
                    </SoftTypography>

                    <SoftSelect
                      customStyles={{
                        option: {
                          minHeight: "30px",
                          display: "inline-flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        },
                      }}
                      placeholder="Select Aircraft"
                      onChange={(e) => {
                        const foundAircraft = aircrafts.find(
                          (a) => a.id === e.value
                        );
                        if (foundAircraft) setSelectedAircraft(foundAircraft);
                      }}
                      options={aircrafts.map((ac) => ({
                        value: ac.id,
                        label: `${ac.tailNumber} - ${ac.displayName}`,
                      }))}
                    />
                  </SoftBox>
                </Grid>
              )}
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Description
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftInput
                      inputRef={ref}
                      value={value}
                      placeholder="Write your notes"
                      multiline
                      rows={4}
                      onChange={onChange}
                      width="100%"
                    />
                  )}
                />
                {errors?.description?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.description.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12}>
                <SoftBox display="flex" mt={1} flexDirection="row-reverse">
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    onClick={handleSetGrounded}
                    ml={1}
                    sx={{
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    Ground Aircraft?
                  </SoftTypography>
                  <Checkbox checked={grounded} onClick={handleSetGrounded} />
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    Add Squawk
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddSquawkModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  aircraft: PropTypes.object,
};
