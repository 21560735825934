import * as toastr from "toastr";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { addDoc, doc, updateDoc } from "firebase/firestore";
import { getClubSquawkStatusesCollection } from "src/features/club/collections";

const schema = yup
  .object({
    label: yup.string().required("A label for the status is required."),
    grounding: yup.boolean(),
  })
  .required();

interface AddSquawkStatusModalProps {
  handleClose: () => void;
  status?: any;
}

export function AddSquawkStatusModal({
  handleClose,
  status,
}: AddSquawkStatusModalProps) {
  const { selectedClubId } = useClubs();

  const defaultValues = {
    label: status?.label || "",
    grounding: status?.grounding || false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const isGrounding: boolean = watch("grounding");

  const handleGroundingToggle = () => setValue("grounding", !isGrounding);

  const onSubmit = async (data: any) => {
    const newStatus = {
      label: data.label,
      grounding: data.grounding,
      deleted: false,
    };

    if (status) {
      await updateDoc(
        doc(getClubSquawkStatusesCollection(selectedClubId), status.id),
        newStatus
      );
    } else {
      await addDoc(getClubSquawkStatusesCollection(selectedClubId), newStatus);
    }
    toastr.success("Squawk status added successfully.");
    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {status ? "Edit Status" : "Add Status"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Label
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Eg. Verified"
                  {...register("label", {
                    required: true,
                  })}
                />
                {errors?.label?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.label.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={4}>
                <SoftBox ml={0.5} pt={5} display="inline-block">
                  <Controller
                    control={control}
                    name="grounding"
                    render={({ field: { onChange, ref, value } }) => (
                      <Checkbox
                        checked={value}
                        onClick={onChange}
                        inputRef={ref}
                      />
                    )}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleGroundingToggle}
                    sx={{
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    Grounding Status?
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    Add
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddSquawkStatusModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  status: PropTypes.object,
};
