import { createContext, useContext, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import {
  getUserStagesCollection,
  getUserLessonsCollection,
  getUserCoursesCollection,
  getUserEnrolledCoursesCollection,
  getUserEnrollmentsCollectionGroup,
  getUserLessonGradingsCollection,
  getUserEnrollmentsCollection,
} from "src/features/user/collections";
import {
  getClubCoursesCollection,
  getCourseStagesCollection,
  getCourseLessonsCollection,
  getLessonTasksCollection,
  getLessonDocumentsCollection,
} from "src/features/lms/collections";

import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import {
  getDocs,
  getDoc,
  doc,
  where,
  query,
  orderBy,
} from "firebase/firestore";
import { entityCrudUtils } from "../firebase/firestore/entityCrudUtils";
import { getClubBookingsCollection } from "../club/collections";
import { differenceInSeconds } from "date-fns";

const LmsContext = createContext(null);
LmsContext.displayName = "LmsContext";

function LmsProvider({ children }) {
  const { user, userId } = useUser();
  const { updateData } = entityCrudUtils();
  const { selectedClubId, selectedLocationId } = useClubs();
  const [activeCourse, setActiveCourse] = useState(null);
  const [allStages, setAllStages] = useState(null);
  const [allStageGrades, setAllStageGrades] = useState(null);
  const [currentStage, setCurrentStage] = useState(null);
  const [allLessons, setAllLessons] = useState(null);
  const [allLessonGrades, setAllLessonGrades] = useState(null);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [isStudent, setIsStudent] = useState(false);

  const userCoursesRef = query(
    getUserCoursesCollection(user?.uid),
    where("locationId", "==", selectedLocationId || "undefined")
  );
  const { data: enrolledCourses, isDataLoaded: userCoursesLoaded } =
    useRealtimeCollectionData(userCoursesRef, false);

  const { data: gradedLessons } = useRealtimeCollectionData(
    query(
      getUserLessonsCollection(
        userId || "undefined",
        activeCourse?.id || "undefined"
      ),
      where("locationId", "==", selectedLocationId || "undefined")
    ),
    false
  );

  const { data: gradedStages } = useRealtimeCollectionData(
    query(
      getUserStagesCollection(
        userId || "undefined",
        activeCourse?.id || "undefined"
      ),
      where("locationId", "==", selectedLocationId || "undefined")
    ),
    false
  );

  useEffect(() => {
    setAllStageGrades(gradedStages);
  }, [gradedStages]);

  useEffect(() => {
    setAllLessonGrades(gradedLessons);
  }, [gradedLessons]);

  const { data: courseStages } = useRealtimeCollectionData(
    query(
      getCourseStagesCollection(selectedClubId, activeCourse?.courseId),
      orderBy("index", "asc")
    ),
    false
  );
  const { data: currentStageLessons } = useRealtimeCollectionData(
    query(
      getCourseLessonsCollection(
        selectedClubId,
        activeCourse?.courseId,
        currentStage?.id
      ),
      orderBy("index", "asc")
    ),
    false
  );

  useEffect(() => {
    if (enrolledCourses) {
      enrolledCourses.forEach((document) => {
        if (document.status === "active") {
          setIsStudent(true);
          setActiveCourse(document);
        }
      });
    }
  }, [enrolledCourses, userCoursesLoaded]);

  useEffect(() => {
    setAllStages(courseStages);
    let currentStageSet = false;
    courseStages?.map((document) => {
      const grade = gradedStages.find((element) => element.id === document.id);
      if (!currentStageSet && !document.complete) {
        setCurrentStage({ ...document, id: document.id, grade });
        currentStageSet = true;
      }
    });
  }, [courseStages, gradedStages]);

  const getCurrentStageLessons = async () => {
    const lessonsWithTasks = await Promise.all(
      currentStageLessons?.map(async (lesson) => {
        const tasks = await getDocs(
          query(
            getLessonTasksCollection(
              selectedClubId,
              activeCourse?.courseId,
              currentStage?.id,
              lesson.id
            ),
            orderBy("index", "asc")
          )
        );
        return {
          ...lesson,
          tasks: tasks?.docs?.map((task) => ({ ...task.data(), id: task.id })),
        };
      })
    );
    setAllLessons(lessonsWithTasks);
    let currentLessonSet = false;
    currentStageLessons?.map((document) => {
      const grade = gradedLessons.find((element) => element.id === document.id);
      if (!currentLessonSet && !document.complete) {
        setCurrentLesson({ ...document, id: document.id, grade });
        currentLessonSet = true;
      }
    });
  };

  useEffect(() => {
    getCurrentStageLessons();
  }, [currentStageLessons, gradedLessons]);

  // useEffect(() => {
  //   setActiveCourse(activeCourse);
  // }, [activeCourse]);

  // Takes in an enrolledCourse and lessonGrades to get all current course & progress information
  const formatEnrolledCourse = async (enrolledCourse, lessonGrades) => {
    // get course and associated stage documents
    const courseRef = await getDoc(
      doc(
        getClubCoursesCollection(selectedClubId),
        enrolledCourse.course?.value
      )
    );

    const stages = await getDocs(
      query(
        getCourseStagesCollection(selectedClubId, enrolledCourse.course?.value),
        where("deleted", "==", false),
        orderBy("index", "asc")
      )
    );

    // initialize the progress tracking variables
    const progress = {
      stages: {
        total: stages.docs.length,
        completed: 0,
        byIndex: [],
      },
      lessons: {
        total: 0,
        completed: 0,
      },
      tasks: {
        total: 0,
        completed: 0,
      },
    };
    let courseCurrentStageIndex = 0;
    let courseCurrentLesson;
    let status = enrolledCourse.status;

    const stagesWithLessons = await Promise.all(
      stages.docs.map(async (stage) => {
        // get lessons and lessonGrades from firestore
        const lessons = await getDocs(
          query(
            getCourseLessonsCollection(
              selectedClubId,
              enrolledCourse.course?.value,
              stage.id
            ),
            where("deleted", "==", false),
            orderBy("index", "asc")
          )
        );
        // Update the progress object to match the lessonGrade data
        const lessonsCompleted = lessonGrades.docs?.filter(
          (lessonGrade) =>
            lessonGrade.data().submitted === true &&
            lessonGrade.data().lessonGrade === "S" &&
            lessonGrade.data().stageId === stage.id
        ).length;
        const stageProgress = Math.floor(
          (lessonsCompleted / lessons.docs.length) * 100
        );
        if (stageProgress === 100) {
          progress.stages.completed += 1;
          if (stage.data().index + 1 !== stages.docs.length) {
            courseCurrentStageIndex += 1;
          }
        }
        progress.stages.byIndex.push({
          progress: stageProgress,
          lessonsCompleted,
        });
        progress.lessons.total += lessons.docs.length;
        // return formatted stage data
        return {
          ...stage.data(),
          id: stage.id,
          editable: stage.data().index <= progress.stages.completed,
          lessons: await Promise.all(
            lessons.docs.map(async (lesson) => {
              // find lessonGrade and get task data
              const lessonGrade = lessonGrades.docs?.find(
                (l) => l.data().lessonId === lesson.id
              );
              const tasks = await getDocs(
                query(
                  getLessonTasksCollection(
                    selectedClubId,
                    enrolledCourse.course?.value,
                    stage.id,
                    lesson.id
                  ),
                  where("deleted", "==", false),
                  orderBy("index", "asc")
                )
              );
              const documents = await getDocs(
                getLessonDocumentsCollection(
                  selectedClubId,
                  enrolledCourse.course?.value,
                  stage.id,
                  lesson.id
                )
              );
              // update the progress object
              if (
                lessonGrade?.data()?.submitted &&
                lessonGrade.data().lessonGrade === "S"
              ) {
                progress.lessons.completed += 1;
                if (!courseCurrentLesson) {
                  courseCurrentLesson = lesson.id;
                }
              }
              progress.tasks.total += tasks.docs.length;
              // return formatted lesson data
              return {
                ...lesson.data(),
                studentId: enrolledCourse.member?.value,
                instructorId: enrolledCourse.instructor.value,
                enrollmentId: enrolledCourse.enrollmentId,
                enrolledCourseId: enrolledCourse.id,
                id: lesson.id,
                documents: documents.docs.map((document) => ({
                  id: document.id,
                  ...document.data(),
                })),
                grade: { ...lessonGrade?.data(), id: lessonGrade?.id },
                tasks: tasks.docs.map((task) => {
                  if (lessonGrade?.data()?.taskGrades) {
                    if (
                      parseInt(lessonGrade?.data()?.taskGrades[task.id]) < 5
                    ) {
                      progress.tasks.completed += 1;
                    }
                  }
                  return {
                    ...task.data(),
                    id: task.id,
                  };
                }),
              };
            })
          ),
        };
      })
    );
    if (
      (progress.tasks.completed || progress.lessons.completed) &&
      status === "NOT_STARTED"
    ) {
      updateData(
        {
          entity: getUserEnrolledCoursesCollection(
            enrolledCourse.member.value,
            enrolledCourse.enrollmentId
          ),
          pathSegmentsArr: [enrolledCourse.id],
        },
        { status: "IN_PROGRESS" }
      );
      status = "IN_PROGRESS";
    }
    const lessonCompletionPercentage = Math.round(
      (progress.lessons.completed / progress.lessons.total) * 100
    );
    const timeCompletionPercentage = Math.round(
      (differenceInSeconds(new Date(), enrolledCourse.startDate?.toDate()) /
        differenceInSeconds(enrolledCourse.completionDate?.toDate(), enrolledCourse.startDate?.toDate())) *
        100
    );
    const pace = lessonCompletionPercentage - timeCompletionPercentage + 50;
    return {
      ...enrolledCourse,
      ...courseRef.data(),
      id: enrolledCourse.id,
      currentStageIndex: courseCurrentStageIndex,
      currentLesson: courseCurrentLesson,
      stages: stagesWithLessons,
      progress: {
        ...progress,
        pace
      },
      status,
    };
  };

  // // OLD ENROLLMENT STRUCTURE
  // const getCourseDataFromAllEnrollments = async (courseId) => {
  //   const courses = await getDocs(
  //     query(
  //       getUserCoursesCollectionGroup(),
  //       where("status", "==", "active"),
  //       where("clubId", "==", selectedClubId),
  //       where("courseId", "==", courseId),
  //       where("deleted", "==", false)
  //     )
  //   );

  // Takes in an enrollment and adds the info for its enrolledCourses with lessonGrade information
  const getFullEnrollmentData = async (enrollment, instructorId, additionalConstraints = []) => {
    const constraints = [...additionalConstraints];
    if (instructorId) {
      constraints.push(where("instructor.value", "==", instructorId));
    }

    const enrolledCoursesData = await getDocs(
      query(
        getUserEnrolledCoursesCollection(
          enrollment.data().member.value,
          enrollment.id
        ),
        ...constraints,
        where("deleted", "==", false)
      )
    );

    const lessonGrades = await getDocs(
      query(
        getUserLessonGradingsCollection(
          enrollment.data().member.value,
          enrollment.id
        )
      )
    );

    const enrolledCourses = await Promise.all(
      enrolledCoursesData.docs.map(async (enrolledCourseDoc) => {
        const enrolledCourseData = {
          id: enrolledCourseDoc.id,
          ...enrolledCourseDoc.data(),
          member: enrollment.data().member,
          graduationDate: enrollment.data().graduationDate,
          startDate: enrollment.data().startDate
        };
        const enrolledCourse = await formatEnrolledCourse(
          enrolledCourseData,
          lessonGrades
        );
        return enrolledCourse;
      })
    );

    return {
      ...enrollment.data(),
      id: enrollment.id,
      enrolledCourses,
    };
  };

  // Takes in optional locationId & instructorId to return full enrollment data for a club
  const getClubEnrollmentsData = async (locationId, instructorId, additionalConstraints = []) => {
    const constraints = [where("clubId", "==", selectedClubId)];
    if (locationId) {
      constraints.push(where("locationId", "==", locationId));
    }
    const enrollmentsData = await getDocs(
      query(
        getUserEnrollmentsCollectionGroup(),
        ...constraints,
        where("deleted", "==", false)
      )
    );

    const returnData = Promise.all(
      enrollmentsData.docs.map(
        async (enrollmentDoc) =>
          await getFullEnrollmentData(enrollmentDoc, instructorId, additionalConstraints)
      )
    );
    return returnData;
  };

  const getStudentEnrollmentsData = async (studentId) => {
    const enrollmentsData = await getDocs(
      query(
        getUserEnrollmentsCollection(studentId),
        where("clubId", "==", selectedClubId),
        where("deleted", "==", false)
      )
    );

    const returnData = Promise.all(
      enrollmentsData.docs.map(
        async (enrollmentDoc) => await getFullEnrollmentData(enrollmentDoc)
      )
    );

    return returnData;
  };

  const getUserEnrollmentsData = async (userId) => {
    const studentEnrollments = await getStudentEnrollmentsData(userId);
    const instructorEnrollments = await getClubEnrollmentsData(null, userId);
    return [...studentEnrollments, ...instructorEnrollments];
  };

  // Used in the lesson and course view pages to obtain the info for a single enrolledCourse
  const getSingleEnrolledCourse = async (
    studentId,
    enrollmentId,
    enrolledCourseId
  ) => {
    const enrollmentDoc = await getDoc(
      doc(getUserEnrollmentsCollection(studentId), enrollmentId)
    );

    const enrolledCourseDoc = await getDoc(
      doc(
        getUserEnrolledCoursesCollection(studentId, enrollmentId),
        enrolledCourseId
      )
    );
    const lessonGrades = await getDocs(
      query(
        getUserLessonGradingsCollection(studentId, enrollmentId),
        where("courseId", "==", enrolledCourseDoc.data().course?.value)
      )
    );

    const enrolledCourse = await formatEnrolledCourse(
      {
        ...enrolledCourseDoc.data(),
        id: enrolledCourseDoc.id,
        member: enrollmentDoc.data().member,
        startDate: enrollmentDoc.data().startDate,
        graduationDate: enrollmentDoc.data().graduationDate,
      },
      lessonGrades
    );
    return {
      ...enrolledCourse,
      member: enrollmentDoc.data().member,
    };
  };

  const getLessonBookings = async (lessonId, memberId) => {
    const bookingData = await getDocs(
      query(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        where("extendedProps.pilot.value", "==", memberId),
        where("extendedProps.lesson.value", "==", lessonId)
      )
    );
    const bookings = bookingData.docs.map((booking) => ({
      id: booking.id,
      ...booking.data(),
    }));
    return bookings;
  };

  const checkIsStudent = async (userId) => {
    const courses = await getDocs(
      query(
        getUserCoursesCollection(userId),
        where("status", "==", "active"),
        where("deleted", "==", false)
      )
    );
    if (courses.docs.length > 0) return true;

    return false;
  };

  const checkIsApprovedInstructor = (lesson) => {
    const permissions = lesson?.gradingPermissions;
    const isAssignedInstructor = userId === lesson.instructorId;
    if (userId === lesson.studentId) {
      return false;
    }
    if (permissions?.approvedInstructors?.length) {
      return !!permissions.approvedInstructors.find((instructor) => {
        return instructor.value === userId;
      });
    }
    switch (true) {
      case !permissions:
      case permissions?.allowAssignedInstructor &&
        permissions?.allowUnassignedInstructors:
      case isAssignedInstructor && permissions?.allowAssignedInstructor:
      case !isAssignedInstructor && permissions?.allowUnassignedInstructors:
        return true;
      default:
        return false;
    }
  };

  const value = {
    enrolledCourses,
    activeCourse,
    allStages,
    allStageGrades,
    currentStage,
    allLessons,
    allLessonGrades,
    currentLesson,
    isStudent,
    checkIsStudent,
    getClubEnrollmentsData, // gets enrollment data for the current club, optionally filtered by location and/or instructor
    getSingleEnrolledCourse, // gets enrollment data for a single enrolledCourse
    getStudentEnrollmentsData, // gets enrollments where user is student
    getUserEnrollmentsData, // gets all enrollments where user is either student or instructor
    getLessonBookings,
    checkIsApprovedInstructor,
  };

  return <LmsContext.Provider value={value}>{children}</LmsContext.Provider>;
}

function useLms() {
  const context = useContext(LmsContext);

  if (!context) {
    throw new Error("useLms should be used inside the LmsContextProvider.");
  }

  return context;
}

LmsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LmsProvider, useLms };
