import { AttachFile } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import borders from "src/assets/theme/base/borders";
import boxShadows from "src/assets/theme/base/boxShadows";
import colors from "src/assets/theme/base/colors";
import SliderCard from "src/components/SliderCard";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { useLms } from "src/features/lms/LmsProvider";
import { useUser } from "src/features/user/UserProvider";

export default function LessonSliderCard({
  lesson,
  isUpcoming,
}: {
  lesson: any;
  isUpcoming: boolean;
}) {
  const { userId } = useUser();
  const { getLessonBookings }: any = useLms();
  const [loggedHours, setLoggedHours] = useState(0);
  const navigate = useNavigate();

  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const handleMouseDown = (e: any) => {
    setStartX(e.pageX);
    setDragging(false);
  };

  const handleTouchStart = (e: any) => {
    setStartX(e.touches[0].pageX);
    setDragging(false);
  };

  const handleMouseMove = (e: any) => {
    if (Math.abs(e.pageX - startX) > 5) {
      setDragging(true);
    }
  };

  const handleTouchMove = (e: any) => {
    if (Math.abs(e.touches[0].pageX - startX) > 5) {
      setDragging(true);
    }
  };

  const getLoggedHours = async () => {
    const bookings = await getLessonBookings(lesson.id, userId);
    const flightHours = bookings.reduce(
      (acc: any, booking: any) =>
        acc + (booking?.flightData?.instructionHours || 0),
      0
    );
    const flightHoursGround = bookings.reduce(
      (acc: any, booking: any) =>
        acc + (booking?.flightData?.instructionHoursGround || 0),
      0
    );
    setLoggedHours(flightHours + flightHoursGround);
  };

  const handleCardClick = () => {
    if (!dragging) {
      navigate(
        `/training/classroom/${userId}/lessons/${lesson.enrollmentId}/${lesson.enrolledCourseId}/${lesson.id}`
      );
    }
  };

  useEffect(() => {
    getLoggedHours();
  }, []);

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleCardClick}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleCardClick}
    >
      <SliderCard
        sx={{
          my: 2,
          boxShadow: boxShadows.xs,
          p: 1,
          border: 2,
          borderColor: isUpcoming ? colors.primary.main : "rgba(0,0,0,0)",
          overflow: "visible",
        }}
        pr={3}
        // onClick={handleCardClick}
      >
        <SoftBox display="flex" gap={1}>
          <SoftTypography
            fontSize="small"
            fontWeight="medium"
            sx={{
              border: 1,
              borderRadius: borders.borderRadius.md,
              borderColor: borders.borderColor,
              textAlign: "center",
              height: "1.5rem",
              width: "1.5rem",
            }}
          >
            {lesson.index + 1}
          </SoftTypography>
          <SoftBox sx={{ flexGrow: 1 }}>
            {isUpcoming ? (
              <SoftTypography
                fontSize="small"
                color="white"
                lineHeight={1.18}
                component="span"
                sx={{
                  mt: -3.2,
                  position: "absolute",
                  backgroundColor: colors.primary.main,
                  borderRadius: `${borders.borderRadius.sm} ${borders.borderRadius.sm} 0 0`,
                  px: 1,
                }}
              >
                Upcoming
              </SoftTypography>
            ) : null}
            <SoftTypography fontSize="medium" fontWeight="medium">
              {lesson.title}
            </SoftTypography>
            <SoftTypography width="250px" fontSize="small" fontWeight="regular">
              Flight: {lesson.flightTime} / Gound:{" "}
              {lesson.groundTime + lesson.simTime} / Tasks:{" "}
              {lesson.tasks.length}
            </SoftTypography>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="end"
            >
              <SoftTypography fontSize="small" fontWeight="regular">
                Logged: {loggedHours} hrs
              </SoftTypography>
              <SoftBox display="flex" gap={0.25} alignItems="end">
                <SoftTypography
                  lineHeight={1}
                  fontSize="medium"
                  fontWeight="medium"
                >
                  <AttachFile color="secondary" />
                </SoftTypography>
                <SoftTypography fontSize="small" fontWeight="medium">
                  {lesson.documents.length}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SliderCard>
    </div>
  );
}
