// Custom styles for SoftProgress
import { useUser } from "src/features/user/UserProvider";
import { useLms } from "src/features/lms/LmsProvider";
import { useEffect, useMemo, useState } from "react";
import ProgressPaceBar from "src/components/ProgressPaceBar";

interface IProgressTrackerProps {
  course: any;
}

const calculateProgress = (target: number, actual: number) => {
  const difference = actual - target;

  // Cap the difference to -10 to 10
  const cappedDifference = Math.max(Math.min(difference, 20), -20);

  // Map the difference (-10 to 10) to a scale (0 to 100)
  // When difference is -10, progress is 0; when 0, progress is 50; when 10, progress is 100
  return Math.max(Math.min(50 + cappedDifference * -1 * 2.5, 100), 1);
};

const ProgressTracker = ({ course }: IProgressTrackerProps) => {
  const { userId } = useUser();
  const { getLessonBookings }: any = useLms();
  const [sliderValue, setSliderValue] = useState(50);
  const [targetHours, setTargetHours] = useState(0);
  const [loggedHours, setLoggedHours] = useState(0);
  let loggedHoursAcc = 0;
  let targetHoursAcc = 0;

  const getLoggedHours = async (lesson: any) => {
    if (!lesson?.id || !userId) return;
    const bookings = await getLessonBookings(lesson.id, userId);
    const flightHours = bookings.reduce(
      (acc: any, booking: any) =>
        acc + (booking?.flightData?.instructionHours || 0),
      0
    );
    const flightHoursGround = bookings.reduce(
      (acc: any, booking: any) =>
        acc + (booking?.flightData?.instructionHoursGround || 0),
      0
    );
    loggedHoursAcc += flightHours + flightHoursGround;
    if (flightHours + flightHoursGround > 0) {
      targetHoursAcc += lesson?.flightTime || 0;
      targetHoursAcc += lesson?.groundTime || 0;
      targetHoursAcc += lesson?.simTime || 0;
    }
    setLoggedHours(loggedHoursAcc);
    setTargetHours(targetHoursAcc);
  };

  useMemo(() => {
    console.log("course", course);
    if (!course) return;
    loggedHoursAcc = 0;
    targetHoursAcc = 0;
    for (const key in course?.stages) {
      if (Object.prototype.hasOwnProperty.call(course?.stages, key)) {
        const stage = course?.stages[key];

        for (const key2 in stage?.lessons) {
          if (Object.prototype.hasOwnProperty.call(stage?.lessons, key2)) {
            const lesson = stage?.lessons[key2];
            getLoggedHours(lesson);
          }
        }
      }
    }
  }, [course]);

  useEffect(() => {
    const progress = calculateProgress(targetHours, loggedHours);
    setSliderValue(progress);
  }, [targetHours, loggedHours]);

  return (
    <ProgressPaceBar
      size="medium"
      value={sliderValue}
      sx={{ mb: 1 }}
      variant="gradient"
    />
  );
};

export default ProgressTracker;
