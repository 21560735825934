import SingleNote from "../SingleNote";
import { INote } from "../interface";
import TabPanel from "src/components/TabPanel";
import { Modal } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import { useEffect, useMemo, useState } from "react";
import { IUseClub } from "src/interfaces";
import { useClubs } from "src/features/club/ClubProvider";

import BookingInfoModal from "src/modals/BookingInfoModal";
import {
  fetchAircraftResourceData,
  fetchClubPilotsData,
  fetchInstructorResourceData,
} from "src/pages/book-flight/utils";

interface INotesListProps {
  bookingNotes: INote[];
  userNotes: INote[];
  selectedTab: number;
  refreshNotes?: () => void;
}
export default function NotesList({
  bookingNotes,
  userNotes,
  selectedTab,
  refreshNotes = () => {},
}: INotesListProps) {
  const {
    selectedClubId,
    selectedLocationId,
    selectedClub,
    locationUsers,
    clubUsersPermissions,
    instructors: clubInstructors,
    instructorPreferences: clubInstructorPrefs,
  } = useClubs() as IUseClub;

  const [resourceData, setResourceData] = useState<any[]>([]);
  const [pilotData, setPilotData] = useState<any[]>([]);
  const [selectedBookingId, setSelectedBookingId] = useState<string>("");
  const [openBookingInfoModal, setOpenBookingInfoModal] = useState(false);

  const instructorsData = useMemo(() => {
    return fetchInstructorResourceData(clubInstructors, clubInstructorPrefs);
  }, [clubInstructors, clubInstructorPrefs]);

  const getBookingInfoData = async () => {
    const aircraftData = await fetchAircraftResourceData(
      selectedClubId,
      selectedLocationId,
      selectedClub
    );
    const pilotsData = fetchClubPilotsData(locationUsers, clubUsersPermissions);

    setResourceData([...instructorsData, ...aircraftData]);
    setPilotData(pilotsData);
  };

  useEffect(() => {
    getBookingInfoData();
  }, []);

  const handleBookingInfoOpen = (bookingId: string) => {
    setSelectedBookingId(bookingId);
    setOpenBookingInfoModal(true);
  };

  const handleBookingInfoClose = () => {
    setOpenBookingInfoModal(false);
    setSelectedBookingId("");
    refreshNotes()
  };

  return (
    <SoftBox>
      <TabPanel value={selectedTab} index={0} sx={{ p: 0 }}>
        {userNotes.map((note) => (
          <SingleNote note={note} />
        ))}
      </TabPanel>
      <TabPanel value={selectedTab} index={1} sx={{ p: 0 }}>
        {bookingNotes.map((note) => (
          <SingleNote
            onBookingNoteClick={handleBookingInfoOpen}
            note={note}
            withCalendarDate
          />
        ))}
      </TabPanel>
      <Modal
        open={openBookingInfoModal}
        onClose={handleBookingInfoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingInfoModal
            bookingId={selectedBookingId}
            handleClose={handleBookingInfoClose}
            pilots={pilotData}
            resources={resourceData}
          />
        </SoftBox>
      </Modal>
    </SoftBox>
  );
}
