import { useEffect, useMemo, useState } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Calendar from "src/components/Calendar/Calendar";
import Footer from "src/components/Footer";
import DashboardNavbar from "src/containers/DashboardNavbar";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import { useUser } from "src/features/user/UserProvider";
import { useCollection } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import {
  getBookingTitle,
  getClassName,
  getBackgroundColor,
} from "src/pages/book-flight/Calendar/uiHelper";
import { useClubs } from "src/features/club/ClubProvider";
import { Modal } from "@mui/material";
import BookingInfoModal from "src/modals/BookingInfoModal";
import { BookingModal } from "src/modals/BookingModal";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import Swal from "sweetalert2";
import {
  fetchAircraftResourceData,
  fetchClubPilotsData,
  fetchInstructorResourceData,
} from "src/pages/book-flight/utils";
import DataTable from "src/components/Tables/DataTable";
import {
  getTableColumns as getTimeOffTableColumns,
  TableActions as TimeOffTableActions,
} from "./TimeOff/tableColumns";
import { getTableColumns as getPendingRequestsTableColumns } from "./PendingRequests/tableColumns";
import { isBefore } from "date-fns";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import { usePermissions } from "src/hooks/usePermissions";

function MySchedule() {
  const { userId } = useUser();
  const {
    selectedClubId,
    selectedClub,
    selectedLocationId,
    locationUsers,
    clubUsersPermissions,
    instructors: clubInstructors,
    instructorPreferences: clubInstructorPrefs,
    locationReservationTypes,
  } = useClubs();
  const { isStaff, isInstructor } = usePermissions();

  const [bookingData, setBookingData] = useState([]);
  const [unavailableBookingData, setUnavailableBookingData] = useState([]);
  const [pendingBookingData, setPendingBookingData] = useState([]);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [openBookingInfo, setOpenBookingInfo] = useState(false);
  const [resourceData, setResourceData] = useState([]);
  const [pilotData, setPilotData] = useState([]);
  const [selection, setSelection] = useState();

  const instructorsData = useMemo(() => {
    return fetchInstructorResourceData(clubInstructors, clubInstructorPrefs);
  }, [clubInstructors, clubInstructorPrefs]);

  const fetchData = async () => {
    const aircraftData = await fetchAircraftResourceData(
      selectedClubId,
      selectedLocationId,
      selectedClub
    );
    const pilotsData = fetchClubPilotsData(locationUsers, clubUsersPermissions);

    setResourceData([...instructorsData, ...aircraftData]);
    setPilotData(pilotsData);
  };

  useEffect(() => {
    fetchData();
  }, [selectedClubId, selectedLocationId, instructorsData]);

  const [instructorBookingsSnapshot, loadingInstructorBookings] = useCollection(
    query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("extendedProps.instructor.value", "==", userId),
      where("cancelled", "==", false)
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const [pilotBookingsSnapshot, loadingPilotBookings] = useCollection(
    query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("extendedProps.pilot.value", "==", userId),
      where("cancelled", "==", false)
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );
  const [pilot2BookingsSnapshot, loadingPilot2Bookings] = useCollection(
    query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("extendedProps.pilot2.value", "==", userId),
      where("cancelled", "==", false)
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (
      loadingPilotBookings ||
      loadingPilot2Bookings ||
      loadingInstructorBookings
    )
      return;

    let bookingSnapshots = [];
    if (pilotBookingsSnapshot.docs.length > 0)
      bookingSnapshots = bookingSnapshots.concat(pilotBookingsSnapshot.docs);
    if (pilot2BookingsSnapshot.docs.length > 0)
      bookingSnapshots = bookingSnapshots.concat(pilot2BookingsSnapshot.docs);
    if (instructorBookingsSnapshot.docs.length > 0)
      bookingSnapshots = bookingSnapshots.concat(
        instructorBookingsSnapshot.docs
      );

    const bookings = [];
    const unavailableBookings = [];
    const pendingBookings = [];

    bookingSnapshots.map((bookingDoc) => {
      const booking = { ...bookingDoc.data(), id: bookingDoc.id };

      const formattedBooking = {
        ...booking,
        title: getBookingTitle(booking, userId, isStaff(), selectedClub),
        start: booking.start.toDate(),
        end: booking.end.toDate(),
        editable: false,
        backgroundColor: getBackgroundColor(
          booking,
          userId,
          locationReservationTypes
        ),
        className: getClassName(booking, userId),
      };

      bookings.push(formattedBooking);
      if (isBefore(new Date(), booking.end.toDate())) {
        if (
          booking.extendedProps.type.value === "unavailable" ||
          booking.extendedProps.type.value === "requestOnly"
        ) {
          unavailableBookings.push(formattedBooking);
        }

        if (!booking.confirmed) {
          pendingBookings.push(formattedBooking);
        }
      }
    });

    setBookingData(bookings);
    setUnavailableBookingData(unavailableBookings);
    setPendingBookingData(pendingBookings);
  }, [
    pilotBookingsSnapshot,
    pilot2BookingsSnapshot,
    instructorBookingsSnapshot,
  ]);

  const handleBookingInfoOpen = (booking) => {
    setSelectedBookingId(booking.id);
    setOpenBookingInfo(true);
  };

  const handleBookingInfoClose = () => {
    setOpenBookingInfo(false);
    setSelectedBookingId(null);
  };

  const handleBookingDelete = (bookingToDelete) => {
    const { updateData } = entityCrudUtils();

    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          updateData(
            {
              entity: getClubBookingsCollection(
                selectedClubId,
                selectedLocationId
              ),
              pathSegmentsArr: [bookingToDelete?.id],
            },
            { cancelled: true }
          ).then((deleteResult) => {
            if (deleteResult?.error) {
              console.error(deleteResult.error.message);
            }
          });
        }
      });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelection(null);
    setOpen(false);
  };

  const handleSelection = (data) => {
    if (isInstructor()) {
      const resource = resourceData.find(
        (instructor) => instructor.id === userId
      );
      setSelection({
        ...data,
        resource: {
          id: resource.id,
          title: resource.title,
          extendedProps: {
            ...resource,
          },
        },
      });
    } else {
      setSelection(data);
    }

    handleOpen();
  };

  const handleTimeOffTableActions = (action, booking) => {
    switch (action) {
      case TimeOffTableActions.DELETE:
        handleBookingDelete(booking);
        break;
      case TimeOffTableActions.EDIT:
        handleBookingInfoOpen(booking);
        break;
      default:
        break;
    }
  };

  const timeOffTableColumns = useMemo(
    () => getTimeOffTableColumns(handleTimeOffTableActions),
    []
  );
  const pendingRequestsTableColumns = useMemo(
    () => getPendingRequestsTableColumns(handleTimeOffTableActions),
    []
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox p={3}>
        <SoftTypography variant="h5">My Schedule</SoftTypography>
      </SoftBox>

      {isInstructor() && (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <SoftBox mb={3} position="relative">
              <Card
                sx={{
                  height: "100%",
                }}
              >
                <SoftBox pt={2} px={2} lineHeight={1}>
                  <SoftTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Pending Requests
                  </SoftTypography>
                </SoftBox>

                <Grid item p={2} xs={12} lg={12}>
                  {pendingBookingData.length > 0 ? (
                    <DataTable
                      table={{
                        columns: pendingRequestsTableColumns,
                        rows: pendingBookingData,
                      }}
                      entriesPerPage={false}
                      isSorted={false}
                      showTotalEntries={false}
                    />
                  ) : (
                    <SoftTypography
                      variant="button"
                      fontWeight="light"
                      textTransform="capitalize"
                    >
                      No Pending Requests
                    </SoftTypography>
                  )}
                </Grid>
              </Card>
            </SoftBox>
          </Grid>

          <Grid item xs={12} lg={6}>
            <SoftBox mb={3} position="relative">
              <Card
                sx={{
                  height: "100%",
                }}
              >
                <SoftBox pt={2} px={2} lineHeight={1}>
                  <SoftTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Time Off/Request Only
                  </SoftTypography>
                </SoftBox>

                <Grid item p={2} xs={12} lg={12}>
                  {unavailableBookingData.length > 0 ? (
                    <DataTable
                      table={{
                        columns: timeOffTableColumns,
                        rows: unavailableBookingData,
                      }}
                      entriesPerPage={false}
                      isSorted={false}
                      showTotalEntries={false}
                    />
                  ) : (
                    <SoftTypography
                      variant="button"
                      fontWeight="light"
                      textTransform="capitalize"
                    >
                      No Time Off Booked
                    </SoftTypography>
                  )}
                </Grid>
              </Card>
            </SoftBox>
          </Grid>
        </Grid>
      )}

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SoftBox mt={3}>
              <Card>
                <Calendar
                  events={bookingData}
                  eventClick={(info) => handleBookingInfoOpen(info.event)}
                  displayEventEnd
                  listView
                  selectable
                  select={handleSelection}
                  initialView="timeGridWeek"
                  /*    events={bookingData}
                    pilots={pilotData}
                    resourceLabelContent={renderResourceContent}
                    resources={fetchResourceData}
                    resourceData={resourceData}
                    // eventDidMount={eventDidMount}
                    eventClick={(info) => handleBookingInfoOpen(info.event)} */
                />
              </Card>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingModal
            selection={selection}
            handleClose={handleClose}
            resources={resourceData}
            pilots={pilotData}
          />
        </SoftBox>
      </Modal>

      <Modal
        open={openBookingInfo}
        onClose={handleBookingInfoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingInfoModal
            bookingId={selectedBookingId}
            handleClose={handleBookingInfoClose}
            pilots={pilotData}
            resources={resourceData}
          />
          {/*
          <CancelBookingModal
            handleClose={handleCancelBookingClose}
            bookingRef={bookingDataRef}
            booking={{ ...bookingData, id: selectedBookingId }}
          />
          */}
        </SoftBox>
      </Modal>

      <Footer />
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_MY_SCHEDULE,
  MySchedule
);
