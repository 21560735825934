import SoftBox from "src/components/SoftBox";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";

const iconButtonStyles = ({ typography: { size }, breakpoints }: any) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  TOGGLE: "TOGGLE",
};

export const getTableColumns = (onAction: any) => [
  {
    Header: "Label",
    accessor: "label",
  },
  {
    Header: "Actions",
    width: "13%",
    Cell: ({ row: { original } }: any) => (
      <SoftBox sx={{ display: "flex" }}>
        <IconButton
          aria-label="delete"
          sx={iconButtonStyles}
          onClick={() => onAction(TableActions.DELETE, original)}
        >
          <Icon>delete</Icon>
        </IconButton>
        <IconButton
          aria-label="edit"
          sx={iconButtonStyles}
          onClick={() => onAction(TableActions.EDIT, original)}
        >
          <Icon>edit</Icon>
        </IconButton>
      </SoftBox>
    ),
  },
];
