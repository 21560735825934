import { useEffect, useState, useMemo } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Calendar from "src/components/Calendar/Calendar";
import Footer from "src/components/Footer";
import DashboardNavbar from "src/containers/DashboardNavbar";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import { useUser } from "src/features/user/UserProvider";
import { useCollection } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import {
  getBackgroundColor,
  getClassName,
} from "src/pages/book-flight/Calendar/uiHelper";
import { useClubs } from "src/features/club/ClubProvider";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";

import {
  fetchAircraftResourceData,
  fetchClubPilotsData,
  fetchInstructorResourceData,
} from "src/pages/book-flight/utils";

import SoftSelect from "src/components/SoftSelect";
import { Modal } from "@mui/material";
import { BookingModal } from "src/modals/BookingModal";
import BookingInfoModal from "src/modals/BookingInfoModal";
import { startOfDay } from "date-fns";

const allSelectValue = { label: "All", value: "all" };

function AircraftSchedule() {
  const { userId } = useUser();
  const {
    selectedClubId,
    selectedLocationId,
    selectedClub,
    locationUsers,
    clubUsersPermissions,
    instructors: clubInstructors,
    instructorPreferences: clubInstructorPrefs,
    locationReservationTypes,
  } = useClubs();
  const [aircraft, setAircraft] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [selectedAircraft, setSelectedAircraft] = useState(allSelectValue);
  const [selectValues, setSelectValues] = useState([allSelectValue]);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [openBookingInfo, setOpenBookingInfo] = useState(false);
  const [resourceData, setResourceData] = useState([]);
  const [pilotData, setPilotData] = useState([]);
  const [selection, setSelection] = useState();
  const [calendarStartDate, setCalendarStartDate] = useState(new Date());

  const instructors = useMemo(() => {
    return fetchInstructorResourceData(clubInstructors, clubInstructorPrefs);
  }, [clubInstructors, clubInstructorPrefs]);

  const fetchData = async () => {
    const aircraftData = await fetchAircraftResourceData(
      selectedClubId,
      selectedLocationId,
      selectedClub
    );
    const pilotsData = fetchClubPilotsData(locationUsers, clubUsersPermissions);

    setResourceData([...instructors, ...aircraftData]);
    setPilotData(pilotsData);
    setAircraft(aircraftData);
  };

  useEffect(() => {
    fetchData();
  }, [selectedClubId, selectedLocationId, instructors]);

  const [bookingsSnapshot, loadingBookings] = useCollection(
    query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("end", ">=", startOfDay(calendarStartDate)),
      where("cancelled", "==", false)
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (aircraft?.count === 0) return;

    const airplaneSelect = [allSelectValue];

    aircraft.map((x) => {
      airplaneSelect.push({
        label: x.title,
        value: x.id,
      });
    });

    setSelectValues(airplaneSelect);
  }, [aircraft]);

  useEffect(() => {
    if (loadingBookings) return;
    const addLoadedBookings = (bookings, loadedBookings) => {
      const selectedId = selectedAircraft.value;

      loadedBookings.map((document) => {
        const booking = document.data();

        if (
          booking.extendedProps.aircraft &&
          booking.extendedProps.aircraft.value
        ) {
          if (
            selectedId !== "all" &&
            booking.extendedProps.aircraft.value !== selectedId
          )
            return;

          bookings.push({
            ...booking,
            title: booking.extendedProps.aircraft.label,
            id: document.id,
            start: booking.start.toDate(),
            end: booking.end.toDate(),
            editable: false,
            backgroundColor: getBackgroundColor(
              booking,
              userId,
              locationReservationTypes
            ),
            className: getClassName(booking, userId),
          });
        }
      });
    };

    const bookings = [];
    addLoadedBookings(bookings, bookingsSnapshot.docs);

    setBookingData(bookings);
  }, [bookingsSnapshot, selectedAircraft]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelection(null);
    setOpen(false);
  };

  const handleSelection = (data) => {
    if (selectedAircraft && selectedAircraft.value !== "all") {
      const resource = resourceData.find(
        (aircraftResource) => aircraftResource.id === selectedAircraft.value
      );
      setSelection({
        ...data,
        resource: {
          id: resource.id,
          title: resource.title,
          extendedProps: {
            ...resource,
          },
        },
      });
    } else {
      setSelection(data);
    }

    handleOpen();
  };

  const handleSelectionChange = (event) => {
    setSelectedAircraft(event);
  };

  const handleBookingInfoOpen = (booking) => {
    setSelectedBookingId(booking.id);
    setOpenBookingInfo(true);
  };

  const handleBookingInfoClose = () => {
    setOpenBookingInfo(false);
    setSelectedBookingId(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox p={3}>
        <SoftTypography variant="h5">Aircraft</SoftTypography>
      </SoftBox>

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <SoftSelect
              options={selectValues}
              value={selectedAircraft}
              onChange={handleSelectionChange}
            />
          </Grid>
        </Grid>
      </SoftBox>

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SoftBox mt={3}>
              <Card>
                <Calendar
                  events={bookingData}
                  eventClick={(info) => handleBookingInfoOpen(info.event)}
                  displayEventEnd
                  selectable
                  select={handleSelection}
                  initialView="timeGridWeek"
                  datesSet={(dateInfo) => {
                    setCalendarStartDate(dateInfo.start);
                  }}
                  /*    events={bookingData}
                    pilots={pilotData}
                    resourceLabelContent={renderResourceContent}
                    resources={fetchResourceData}
                    resourceData={resourceData}
                    // eventDidMount={eventDidMount}
                    eventClick={(info) => handleBookingInfoOpen(info.event)} */
                />
              </Card>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingModal
            selection={selection}
            handleClose={handleClose}
            resources={resourceData}
            pilots={pilotData}
          />
        </SoftBox>
      </Modal>

      <Modal
        open={openBookingInfo}
        onClose={handleBookingInfoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingInfoModal
            bookingId={selectedBookingId}
            handleClose={handleBookingInfoClose}
            pilots={pilotData}
            resources={resourceData}
          />
        </SoftBox>
      </Modal>

      <Footer />
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_AIRCRAFT_SCHEDULE,
  AircraftSchedule
);
