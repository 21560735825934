import Card from "@mui/material/Card";
import SoftBox from "../SoftBox";

export default function SliderCard({
  width = "",
  sx = {},
  onClick = () => {},
  children,
  pr = 4,
}) {
  const boxStyles = { pr };
  if (width) {
    boxStyles.width = width;
  }
  return (
    <SoftBox {...boxStyles}>
      <Card
        onClick={onClick}
        sx={{ ...sx, cursor: onClick ? "pointer" : "default" }}
      >
        {children}
      </Card>
    </SoftBox>
  );
}
