import { forwardRef } from "react";

// Custom styles for the SoftBadge
import SoftBadgeRoot from "src/components/SoftBadge/SoftBadgeRoot";
import { BadgeProps } from "@mui/material";

type softBadgeType = Omit<BadgeProps, "variant" | "color" | "children"> & {
  variant?: "gradient" | "contained";
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark";
  size?: "xs" | "sm" | "md" | "lg";
  circular?: boolean;
  indicator?: boolean;
  border?: boolean;
  children?: React.ReactNode;
  container?: boolean;
};

const SoftBadge = forwardRef<HTMLSpanElement, softBadgeType>(
  (
    {
      color = "info",
      variant = "gradient",
      size = "sm",
      circular = false,
      indicator = false,
      border = false,
      children,
      container = false,
      ...rest
    },
    ref
  ) => (
    <SoftBadgeRoot
      {...rest}
      ownerState={{
        color,
        variant,
        size,
        circular,
        indicator,
        border,
        container,
        children,
      }}
      ref={ref}
      color="default"
    >
      {children}
    </SoftBadgeRoot>
  )
);

export default SoftBadge;
