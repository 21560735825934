// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useState, useEffect, useMemo, createRef } from "react";
import { getFirestore, updateDoc, doc } from "firebase/firestore";
import { firebaseApp } from "src/features/firebase";
import { useClubs } from "src/features/club/ClubProvider";
import { useUser } from "src/features/user/UserProvider";
import WeatherBarLabel from "./WeatherBar";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeline from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";

// @mui material components
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import DatePicker from "react-datepicker";

// Custom styles for Calendar
import CalendarRoot from "./CalendarRoot";

// import 'flatpickr/dist/themes/material_blue.css';
import "react-datepicker/dist/react-datepicker.css";
import { BookingModal } from "src/modals/BookingModal";
import { BookingErrorModal } from "src/modals/BookingErrorModal";
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";
import {
  isWithinInstructorWorkingHours,
  getOverlappingBookings,
  isOverlappingAnotherBooking,
} from "src/pages/book-flight/utils";
import { sub, add } from "date-fns";
import CalendarFilter from "./CalendarFilter";
import SoftButton from "src/components/SoftButton";
import { Divider } from "@mui/material";
import {
  getLocalUserSetting,
  setLocalUserSetting,
} from "src/features/user/LocalUserSettings";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import { useClickOutside } from "src/hooks/useClickOutside";
import { getBackgroundColor } from "./uiHelper";

const validClassNames = [
  "maintenance",
  "maintenance-dispatched",
  "maintenance-dispatched-other",
  "maintenance-completed",
  "maintenance-completed-other",
  "requestOnly",
  "unavailable",
  "checkride",
  "checkout",
  "checkout-other",
  "pilotRefresher",
  "pilotRefresher-other",
  "reservation",
  "reservation-other",
  "introFlight",
  "introFlight-other",
  "dispatched",
  "dispatched-other",
  "completed",
  "completed-other",
  "warning",
  "error",
  "light",
  "dark",
];

const getClassName = (el) => {
  if (!el.confirmed) return "event-unconfirmed";
  return validClassNames.find((item) => item === el.className)
    ? `event-${el.className}`
    : "event-info";
};

function renderWeatherLabelContent(eventInfo) {
  const labelDate = eventInfo.date;
  if (
    eventInfo?.view?.type === "resourceTimeGridFourDay" &&
    eventInfo.level === 1
  ) {
    return <WeatherBarLabel label={eventInfo.text} date={labelDate} />;
  }

  if (eventInfo?.view?.type === "resourceTimeline") {
    return <WeatherBarLabel label={eventInfo.text} date={labelDate} />;
  }
}

function Calendar({ header, resourceData, pilots, ...rest }) {
  const { userId } = useUser();
  const { selectedClub, locationReservationTypes } = useClubs();
  const { hasAccess } = usePermissions();
  const { canCreateBooking, canEditBooking, isUserInAircraftMembershipLevel } =
    useUserPermissions();
  const [openSnack, setOpenSnack] = useState(false);
  const [selection, setSelection] = useState();
  const [events, setEvents] = useState([]);
  const [errorBooking, setErrorBooking] = useState({});
  const [permissionErrors, setPermissionErrors] = useState([]);
  const handleSetSelection = (data) => setSelection(data);
  const calendarRef = createRef();

  const [filteredInstructorResources, setFilteredInstructorResources] =
    useState([]);
  const [filteredAircraftResources, setFilteredAircraftResources] = useState(
    []
  );

  useEffect(() => {
    setFilteredInstructorResources(
      getLocalUserSetting(userId)?.filteredInstructorResources?.filter(
        (fir) => {
          return resourceData.some((rd) => rd.id === fir.id);
        }
      ) || resourceData.filter((el) => el.type === "Instructors")
    );

    setFilteredAircraftResources(
      getLocalUserSetting(userId)?.filteredAircraftResources?.filter((fir) => {
        return resourceData.some((rd) => rd.id === fir.id);
      }) ||
        resourceData.filter(
          (el) => el.type === "Aircraft" || el.type === "Simulators"
        )
    );
  }, [resourceData]);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobileSize = width <= 730;

  const [showBookingErrorModal, setShowBookingErrorModal] = useState(false);
  const handleCloseBookingErrorModal = (refreshEvents) => {
    if (refreshEvents) setupEvents();
    setPermissionErrors([]);
    setShowBookingErrorModal(false);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    handleSetSelection(null);
    setOpen(false);
  };

  const setupEvents = () => {
    const eventArray = [];
    if (rest.events?.length > 0) {
      rest.events.map((el) => {
        if (
          el.extendedProps.aircraft?.value &&
          el.extendedProps.aircraft?.value !== "" &&
          el.extendedProps.instructor?.value &&
          el.extendedProps.instructor?.value !== "" &&
          (el.preGround > 0 || el.postGround > 0)
        ) {
          eventArray.push({
            ...el,
            groupId: el.id,
            id: `dup_${el.id}`,
            resourceEditable: canEditBooking(el),
            resourceIds: [el.extendedProps?.instructor?.value],
            display:
              el.extendedProps?.type?.value === "requestOnly" ||
              el.extendedProps?.type?.value === "unavailable"
                ? "background"
                : "block",
            overlap:
              el.extendedProps.type?.value !== "unavailable" &&
              el.extendedProps.type?.value !== "maintenance",
            className: getClassName(el),
            backgroundColor: getBackgroundColor(el),
            start: sub(el.start, { minutes: el.preGround }),
            end: add(el.end, { minutes: el.postGround }),
          });
          eventArray.push({
            ...el,
            groupId: el.id,
            resourceEditable: canEditBooking(el),
            resourceIds: [el.extendedProps?.aircraft?.value],
            backgroundColor: getBackgroundColor(el),
            display:
              el.extendedProps.type?.value === "requestOnly" ||
              el.extendedProps.type?.value === "unavailable"
                ? "background"
                : "block",
            overlap:
              el.extendedProps.type?.value !== "unavailable" &&
              el.extendedProps.type?.value !== "maintenance",
            className: getClassName(el),
          });
        } else {
          eventArray.push({
            ...el,
            resourceEditable: canEditBooking(el),
            backgroundColor: getBackgroundColor(
              el,
              userId,
              locationReservationTypes
            ),
            display:
              el.extendedProps.type?.value === "requestOnly" ||
              el.extendedProps.type?.value === "unavailable"
                ? "background"
                : "block",
            overlap:
              el.extendedProps.type?.value !== "unavailable" &&
              el.extendedProps.type?.value !== "maintenance",
            className: getClassName(el),
            start: sub(el.start, { minutes: el.preGround ?? 0 }),
            end: add(el.end, { minutes: el.postGround ?? 0 }),
          });
        }
      });
    }

    setEvents(eventArray);
  };

  useEffect(() => {
    setupEvents();
  }, [rest.events]);

  const eventsMemo = useMemo(
    () =>
      events.filter((event) =>
        event.resourceIds.some(
          (id) =>
            filteredAircraftResources.some((el) => el.id === id) ||
            filteredInstructorResources.some((el) => el.id === id)
        )
      ),
    [events, filteredAircraftResources, filteredInstructorResources]
  );

  const handleSelectionOverlap = (event) => {
    if (hasAccess([systemPermissions.ALLOW_OVERLAPPING_BOOKINGS])) return true;
    if (
      selectedClub.preferences?.calendar?.allowOverlappingBookings &&
      event.extendedProps?.type?.value !== "unavailable" &&
      event.extendedProps?.type?.value !== "maintenance"
    ) {
      return true;
    }

    if (event.extendedProps?.type?.value === "requestOnly") return true;

    return false;
  };

  const handleSelection = (data) => {
    handleSetSelection(data);
    handleOpen();
  };

  const saveDateChanges = async (data) => {
    const id = data?.event?.id.replace("dup_", "");
    let { start, end } = data.event;
    if (
      data?.event?.getResources()[0]?.id ===
      data?.event?.extendedProps?.instructor?.value
    ) {
      start = add(start, {
        minutes: data?.event?.extendedProps?.preGround ?? 0,
      });
      end = sub(end, { minutes: data?.event?.extendedProps?.postGround ?? 0 });
    }

    const extendedProps = {
      aircraft: data.event.extendedProps.aircraft,
      clubId: data.event.extendedProps.clubId,
      locationId: data.event.extendedProps.locationId,
      type: data.event.extendedProps.type,
      instructor: data.event.extendedProps.instructor,
      pilot: data.event.extendedProps.pilot,
      pilot2: data.event.extendedProps.pilot2,
    };

    let resourceIds = [];
    data.event.getResources().forEach((resource) => {
      resourceIds.push(resource?.id);
    });
    data.event.resourceIds = resourceIds;

    let hasErrors = false;

    if (!hasAccess(systemPermissions.ALLOW_OVERLAPPING_BOOKINGS)) {
      const isOverlapping = await isOverlappingAnotherBooking(
        data.event,
        selectedClub.preferences
      );
      if (isOverlapping) {
        hasErrors = true;
        setPermissionErrors(
          permissionErrors.concat(["Overlapping another booking"])
        );
      }
    }

    const checkUserInAircraftMembershipLevel =
      await isUserInAircraftMembershipLevel(data.event);
    if (!checkUserInAircraftMembershipLevel.allowed) {
      hasErrors = true;
      setPermissionErrors(
        permissionErrors.concat([
          `You must have a ${checkUserInAircraftMembershipLevel.reason} membership to book this aircraft`,
        ])
      );
    }
    const permissionCheck = await canCreateBooking(data.event);
    if (!permissionCheck.allowed) {
      hasErrors = true;
      setPermissionErrors(permissionErrors.concat(permissionCheck.reasons));
    }

    const bookingRef = doc(
      getFirestore(firebaseApp),
      `clubs/${extendedProps.clubId}/locations/${extendedProps.locationId}/bookings/${id}`
    );

    if (
      data.oldResource &&
      data.newResource &&
      (data.oldResource.extendedProps.type ===
        data.newResource.extendedProps.type ||
        ((data.oldResource.extendedProps.type === "Aircraft" ||
          data.oldResource.extendedProps.type === "Simulators") &&
          (data.newResource.extendedProps.type === "Aircraft" ||
            data.newResource.extendedProps.type === "Simulators")))
    ) {
      if (
        data.oldResource.extendedProps.type === "Aircraft" ||
        data.oldResource.extendedProps.type === "Simulators"
      ) {
        extendedProps.aircraft = {
          label: data.newResource.title,
          value: data.newResource.id,
        };
      } else if (data.oldResource.extendedProps.type === "Instructors") {
        extendedProps.instructor = {
          label: data.newResource.title,
          value: data.newResource.id,
        };
      }
    }

    resourceIds = [];
    if (extendedProps.aircraft?.value)
      resourceIds.push(extendedProps.aircraft?.value);
    if (extendedProps.instructor?.value)
      resourceIds.push(extendedProps.instructor?.value);
    if (extendedProps.pilot?.value)
      resourceIds.push(extendedProps.pilot?.value);
    if (extendedProps.pilot2?.value)
      resourceIds.push(extendedProps.pilot2?.value);
    if (extendedProps.member?.value)
      resourceIds.push(extendedProps.member?.value);

    data.event.resourceIds = resourceIds;

    let confirmed = true;

    const isWithinWorking = await isWithinInstructorWorkingHours(data.event);
    const overlappingBookings = await getOverlappingBookings(data.event);
    if (
      extendedProps.type?.value !== "maintenance" &&
      (!isWithinWorking || overlappingBookings.requestOnly.length > 0) &&
      userId !== extendedProps?.instructor?.value
    ) {
      confirmed = false;
    }

    const bookingDataToSave = {
      id,
      start,
      end,
      confirmed,
      extendedProps,
      resourceIds,
      updatedAt: new Date(),
      updatedBy: userId,
    };

    if (hasErrors) {
      setErrorBooking(bookingDataToSave);
      setShowBookingErrorModal(true);
    } else {
      rest.events[
        rest.events.findIndex((event) => event.id === bookingDataToSave.id)
      ] = {
        ...rest.events.find((event) => event.id === bookingDataToSave.id),
        ...bookingDataToSave,
      };
      await updateDoc(bookingRef, bookingDataToSave);
    }
  };

  const setCalendarDate = (date) => {
    calendarRef.current.getApi().gotoDate(date);
  };

  const setMonth = (amount) => {
    const currentDate = calendarRef.current.getApi().view.currentStart;
    if (currentDate) {
      const newDate = add(currentDate, { months: amount });
      setCalendarDate(newDate);
    }
  };

  const datepickerRef = useClickOutside(() => setIsOpen(false));

  const [startDate, setStartDate] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (e) => {
    setIsOpen(!isOpen);
    setCalendarDate(e);
  };

  const showDatePicker = (e) => {
    e.preventDefault();
    setStartDate(calendarRef?.current?.getApi().view.currentStart);
    setIsOpen(!isOpen);
  };

  const handleOpenSnack = () => {
    setOpenSnack(true);
  };
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const saveFilters = () => {
    setLocalUserSetting(userId, {
      filteredAircraftResources,
      filteredInstructorResources,
    });
  };

  return (
    <SoftBox
      sx={{
        height: "100%",
      }}
    >
      <SoftBox
        pt={2}
        pb={1}
        px={2}
        lineHeight={1}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent={isMobileSize ? "center" : "space-between"}
      >
        <SoftBox
          sx={{
            display: "inline-flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <CalendarFilter
            label="Instructors"
            placeholder="All Instructors"
            defaultValues={filteredInstructorResources}
            options={resourceData.filter((el) => el.type === "Instructors")}
            onChange={(e) => {
              setFilteredInstructorResources(e);
            }}
          />
          <CalendarFilter
            label="Aircraft"
            placeholder="All Aircraft"
            defaultValues={filteredAircraftResources}
            options={resourceData.filter(
              (el) => el.type === "Aircraft" || el.type === "Simulators"
            )}
            onChange={(e) => {
              setFilteredAircraftResources(e);
            }}
          />
        </SoftBox>
        <SoftBox mt={4} ml={1}>
          <SoftButton
            variant="outlined"
            size="small"
            color="info"
            onClick={saveFilters}
          >
            Save Filters
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <Divider />
      {header && (
        <SoftBox px={2} lineHeight={1}>
          {header.title ? (
            <SoftTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {header.title}
            </SoftTypography>
          ) : null}
          {header.date ? (
            <SoftTypography
              component="p"
              variant="button"
              color="text"
              fontWeight="medium"
            >
              {header.date}
            </SoftTypography>
          ) : null}
        </SoftBox>
      )}
      <CalendarRoot p={0} pt={0}>
        <SoftBox
          id="datePicker"
          ref={datepickerRef}
          sx={{
            position: "absolute",
            zIndex: 999,
            top: isMobileSize ? "130px" : "80px",
          }}
        >
          {isOpen && (
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              inline
            />
          )}
        </SoftBox>
        {isMobileSize ? (
          <SoftBox
            sx={{
              "& .fc .fc-toolbar.fc-header-toolbar": {
                flexDirection: "column",
                marginBottom: 2,
                ".fc-toolbar-chunk:nth-child(2)": {
                  marginBottom: 2,
                },
              },
              "& .fc .fc-resource .fc-icon": {
                display: "none",
              },
            }}
            onClick={() => {
              handleOpenSnack();
            }}
          >
            <FullCalendar
              {...rest}
              // timeZone={selectedClub?.timeZone || "local"}
              ref={calendarRef}
              refetchResourcesOnNavigate="true"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                resourceTimeline,
                bootstrap5Plugin,
              ]}
              themeSystem="bootstrap5"
              events={eventsMemo}
              schedulerLicenseKey={
                import.meta.env.VITE_FULL_CALENDAR_LICENSE_KEY
              }
              customButtons={{
                datePicker: {
                  text: "Date",
                  icon: "calendar",
                  id: "datePicker",
                  click: showDatePicker,
                },
              }}
              headerToolbar={{
                left: "",
                center: "prev,title,next",
                right: "datePicker,today resourceTimeline,timeGridDay",
              }}
              longPressDelay={250}
              initialView="resourceTimeline"
              views={{
                resourceTimeline: {
                  type: "resourceTimeline",
                  duration: {
                    days: 1,
                  },
                  buttonText: "Horizontal",
                },
              }}
              resourceGroupField="type"
              resources={[
                ...(filteredAircraftResources?.length >= 0 &&
                  filteredAircraftResources),
                ...(filteredInstructorResources?.length >= 0 &&
                  filteredInstructorResources),
              ]}
              height="auto"
              resourceOrder="type"
              // dateClick={handleSelection}
              select={handleSelection}
              selectOverlap={handleSelectionOverlap}
              eventDrop={saveDateChanges}
              eventResize={saveDateChanges}
              slotMinTime="00:00:00"
              slotMaxTime="24:00:00"
              slotMinWidth={
                selectedClub.preferences?.calendar?.slotDuration ?? 15
              }
              slotDuration={{
                minutes: selectedClub.preferences?.calendar?.slotDuration ?? 15,
              }}
              scrollTimeReset={false}
              scrollTime="00:00:00"
              titleFormat={{
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
              }}
              selectable
              nowIndicator
            />
          </SoftBox>
        ) : (
          <FullCalendar
            {...rest}
            // timeZone={selectedClub?.timeZone || "local"}
            ref={calendarRef}
            refetchResourcesOnNavigate="true"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              resourceTimeline,
              resourceTimeGridPlugin,
              bootstrap5Plugin,
            ]}
            themeSystem="bootstrap5"
            // slotLabelContent={renderWeatherLabelContent}
            events={eventsMemo}
            schedulerLicenseKey={import.meta.env.VITE_FULL_CALENDAR_LICENSE_KEY}
            customButtons={{
              datePicker: {
                text: "Date",
                icon: "calendar",
                id: "datePicker",
                click: showDatePicker,
              },
              prevMonth: {
                icon: "chevron-double-left",
                click: () => {
                  setMonth(-1);
                },
              },
              nextMonth: {
                icon: "chevron-double-right",
                click: () => {
                  setMonth(1);
                },
              },
            }}
            headerToolbar={{
              left: "datePicker today",
              center: "prevMonth prev,title,next nextMonth",
              right: "resourceTimeline,timeGridDay,timeGridWeek,dayGridMonth",
            }}
            initialView="resourceTimeline"
            views={{
              timeGridDay: {
                type: "timeGridDay",
                slotDuration: {
                  minutes: 30,
                },
                duration: {
                  days: 1,
                },
              },
              resourceTimeline: {
                type: "resourceTimeline",
                duration: {
                  days: 1,
                },
                slotLabelContent: renderWeatherLabelContent,
                buttonText: "Horizontal",
              },
            }}
            resourceGroupField="type"
            resources={[
              ...(filteredAircraftResources?.length >= 0
                ? filteredAircraftResources
                : []),
              ...(filteredInstructorResources?.length >= 0
                ? filteredInstructorResources
                : []),
            ]}
            height="auto"
            resourceOrder="payload.index"
            // dateClick={handleSelection}
            select={handleSelection}
            selectOverlap={handleSelectionOverlap}
            eventDrop={saveDateChanges}
            eventResize={saveDateChanges}
            slotMinTime="00:00:00"
            slotMaxTime="24:00:00"
            slotMinWidth={
              selectedClub.preferences?.calendar?.slotDuration ?? 15
            }
            slotDuration={{
              minutes: selectedClub.preferences?.calendar?.slotDuration ?? 15,
            }}
            scrollTimeReset={false}
            scrollTime="00:00:00"
            titleFormat={{
              year: "numeric",
              month: "short",
              day: "numeric",
              weekday: "short",
            }}
            selectable
            nowIndicator
          />
        )}
      </CalendarRoot>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingModal
            selection={selection}
            handleClose={handleClose}
            resources={resourceData}
            pilots={pilots}
          />
        </SoftBox>
      </Modal>
      <Modal
        open={showBookingErrorModal}
        onClose={handleCloseBookingErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingErrorModal
            booking={errorBooking}
            permissionErrors={permissionErrors}
            handleClose={handleCloseBookingErrorModal}
          />
        </SoftBox>
      </Modal>
      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ flexDirection: "column" }}
        autoHideDuration={1700}
        onClose={handleCloseSnack}
        message="Long-press & drag to book"
      />
    </SoftBox>
  );
}

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
  resourceData: PropTypes.array,
  pilots: PropTypes.array,
};

export default Calendar;
