import { IPermission } from "./permission.interface";

export enum reportsRoleCategory {
  REPORTS = "REPORTS",
}

export enum reportsPermissionTypes {
  VIEW_REPORTS_SUMMARY = "VIEW_REPORTS_SUMMARY",
  VIEW_RESERVATIONS_REPORTS = "VIEW_RESERVATIONS_REPORTS",
  VIEW_LMS_REPORTS = "VIEW_LMS_REPORTS",
  VIEW_MEMBER_REPORTS = "VIEW_MEMBER_REPORTS",
  VIEW_FLIGHTS_REPORTS = "VIEW_FLIGHTS_REPORTS",
  VIEW_INSTRUCTORS_REPORTS = "VIEW_INSTRUCTORS_REPORTS",
  VIEW_AIRCRAFT_REPORTS = "VIEW_AIRCRAFT_REPORTS",
  VIEW_FINANCIAL_REPORTS = "VIEW_FINANCIAL_REPORTS",
}

export const reportsPermissions: IPermission[] = [
  {
    group: reportsRoleCategory.REPORTS,
    label: "Reports",
    tooltipGroup: "",
    permissions: {
      [reportsPermissionTypes.VIEW_REPORTS_SUMMARY]: {
        label: "View Reports Summary",
        tooltip: "",
      },
      [reportsPermissionTypes.VIEW_LMS_REPORTS]: {
        label: "View LMS Reports",
        tooltip: "",
      },
      [reportsPermissionTypes.VIEW_MEMBER_REPORTS]: {
        label: "View Member Reports",
        tooltip: "",
      },
      [reportsPermissionTypes.VIEW_RESERVATIONS_REPORTS]: {
        label: "View Reservations Reports",
        tooltip: "",
      },
      [reportsPermissionTypes.VIEW_FLIGHTS_REPORTS]: {
        label: "View Flights Reports",
        tooltip: "",
      },
      [reportsPermissionTypes.VIEW_INSTRUCTORS_REPORTS]: {
        label: "View Instructors Reports",
        tooltip: "",
      },
      [reportsPermissionTypes.VIEW_AIRCRAFT_REPORTS]: {
        label: "View Aircraft Reports",
        tooltip: "",
      },
      [reportsPermissionTypes.VIEW_FINANCIAL_REPORTS]: {
        label: "View Financial Reports",
        tooltip: "",
      },
    },
  },
];
