import { useLocation } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

import { usePermissions } from "src/hooks/usePermissions";
import CourseGrid from "./components/CourseGrid";

function Classroom() {
  const { pathname } = useLocation();
  const { isInstructorOnly } = usePermissions();

  const showAsInstructor = () => {
    if (pathname.includes("classroom")) {
      return false;
    }
    return isInstructorOnly()
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <CourseGrid asInstructor={showAsInstructor()} />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Classroom;
