import { useState, useEffect } from "react";
import SoftBox from "src/components/SoftBox";

import {
  Grid,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
} from "@mui/material";

import ModalContainer from "src/components/ModalContainer";
import LineItemModal from "src/modals/LineItemModal";
import Table from "src/components/Tables/Table";
import SoftButton from "src/components/SoftButton";
import SoftInput from "src/components/SoftInput";
import SoftTypography from "src/components/SoftTypography";
import SoftSelect from "src/components/SoftSelect";
import SoftDatePicker from "src/components/SoftDatePicker";
import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClubs } from "src/features/club/ClubProvider";

import { getFunctions, httpsCallable } from "firebase/functions";
import { ThreeDots } from "react-loader-spinner";
import { getDoc, doc } from "@firebase/firestore";
import { getBillingCollection } from "src/features/billing/collections";
import { formatCurrency } from "src/features/utils";

const schema = yup
  .object({
    date: yup.date().required("A transaction date is required."),
    member: yup.object(),
  })
  .required();

const columns = [
  { name: "item", width: 350, align: "left" },
  { name: "qty", width: 80, align: "left" },
  { name: "price", width: 100, align: "left", isCurrency: true },
  { name: "discount", width: 100, align: "left" },
  { name: "total", width: 100, align: "left", isCurrency: true },
  { name: "", width: 100, align: "right", isActions: true },
];

export function RequestPaymentModal({ handleClose }) {
  const { selectedClubId, selectedLocationId, locationUsers } = useClubs();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [members, setMembers] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [accountBalance, setAccountBalance] = useState(0);
  const [applyAccountBalance, setApplyAccountBalance] = useState(false);
  const handleApplyBalance = () => setApplyAccountBalance(!applyAccountBalance);
  const [enableCC, setEnableCC] = useState(true);
  const [enableACH, setEnableACH] = useState(true);

  const toggleCC = () => setEnableCC(!enableCC);
  const toggleACH = () => setEnableACH(!enableACH);

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      date: new Date(),
      radio: "existing",
    },
    resolver: yupResolver(schema),
  });

  const radiovalue = watch("radio");
  const selectedMember = watch("member");

  useEffect(() => {
    setValue("sendTo", selectedMember?.email || null);
    if (!selectedMember) return;
    getDoc(doc(getBillingCollection(), selectedMember.value)).then(
      (balance) => {
        const balanceData = balance.data();
        if (balanceData?.[`${selectedClubId}`])
          setAccountBalance(balanceData[`${selectedClubId}`]);
        else setAccountBalance(0);
      }
    );
  }, [selectedMember]);

  useEffect(() => {
    const memberOptions = locationUsers.map((member) => ({
      value: member.uid,
      label: member.displayName,
      ...member,
    }));

    setMembers(memberOptions);
  }, [locationUsers]);

  const handleLineItemChange = (event, newValue) => {
    if (!event || !newValue) return;
    const rows = [...lineItems];
    switch (event) {
      case "custom":
        rows.push({
          id: rows.length > 0 ? rows[rows.length - 1].id + 1 : 0,
          type: newValue.transactionType.value,
          item: newValue.description,
          qty: newValue.qty,
          price: parseFloat(newValue.amount),
          discount: 0,
          total: parseFloat(newValue.amount) * parseFloat(newValue.qty),
        });
        break;

      default:
        break;
    }
    setLineItems(rows);
  };

  const onRowDelete = (row) => {
    if (!row) return;
    const rows = Array.from(lineItems);
    rows.splice(row.id, 1);
    setLineItems(rows);
  };

  useEffect(() => {
    const total = lineItems.reduce(
      (acc, item) =>
        item.type === "credit"
          ? acc - parseFloat(item.total)
          : acc + parseFloat(item.total),
      0
    );
    setTotalAmount(total);
  }, [lineItems]);

  const onSubmit = (data) => {
    console.debug("data", data);
    if (totalAmount <= 0) return;
    setIsSubmitting(true);

    const functions = getFunctions();
    const requestPayment = httpsCallable(functions, "requestPayment");

    if (applyAccountBalance && radiovalue != "new") {
      lineItems.push({
        id: lineItems.length,
        type: "accountBalance",
        item: "Existing Account Balance",
        qty: 1,
        price: Math.min(accountBalance, totalAmount),
        discount: 0,
        total: Math.min(accountBalance, totalAmount),
      });
    }

    const paymentRequestData = {
      clubId: selectedClubId,
      locationId: selectedLocationId,
      sendTo: data.sendTo,
      date: data.date,
      paymentMethods: [],
      amount:
        applyAccountBalance && radiovalue != "new"
          ? Math.max(totalAmount - accountBalance, 0)
          : totalAmount,
      lineItems,
    };

    if (enableCC) paymentRequestData.paymentMethods.push("cc");
    if (enableACH) paymentRequestData.paymentMethods.push("ach");

    if (data.radio === "new") {
      paymentRequestData.firstName = data.firstName;
      paymentRequestData.lastName = data.lastName;
      paymentRequestData.email = data.email;
    } else {
      paymentRequestData.userId = data.member.value;
    }

    requestPayment(paymentRequestData)
      .then((res) => {
        watch, console.debug("res", res);
        setIsSubmitting(false);
        handleClose();
      })
      .catch((error) => {
        console.error("error", error);
        setIsSubmitting(false);
      });
  };

  if (isSubmitting) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5" fontWeight="bold">
              Request Payment
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Date
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SoftDatePicker
                        dateFormat="P"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                      />
                    )}
                  />
                  {errors?.date?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.date.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={8} sx={{ alignSelf: "end" }}>
                <Controller
                  control={control}
                  name="radio"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControl sx={{ marginLeft: 3 }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                        ref={ref}
                      >
                        <FormControlLabel
                          value="new"
                          control={<Radio />}
                          label="New Member"
                        />
                        <FormControlLabel
                          value="existing"
                          control={<Radio />}
                          label="Existing Member"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display={radiovalue !== "new" ? "none" : "initial"}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={6}
                    display={radiovalue !== "new" ? "none" : "initial"}
                  >
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        First Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox flexGrow={1} ml={2}>
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftInput
                            onChange={onChange}
                            value={value}
                            ref={ref}
                          />
                        )}
                      />
                      {errors?.firstName?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.firstName.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    display={radiovalue !== "new" ? "none" : "initial"}
                  >
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Last Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox flexGrow={1} ml={2}>
                      <Controller
                        control={control}
                        name="lastName"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftInput
                            onChange={onChange}
                            value={value}
                            ref={ref}
                          />
                        )}
                      />
                      {errors?.lastName?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.lastName.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                display={radiovalue !== "new" ? "none" : "initial"}
              >
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Email
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftInput onChange={onChange} value={value} ref={ref} />
                    )}
                  />
                  {errors?.email?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.email.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid
                item
                xs={6}
                display={radiovalue === "new" ? "none" : "initial"}
              >
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Member
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="member"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={members}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.member?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.member.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              {selectedMember && selectedMember.value && (
                <Grid item xs={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Send To
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox flexGrow={1} ml={2}>
                    <Controller
                      control={control}
                      name="sendTo"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftInput
                          onChange={onChange}
                          value={value}
                          ref={ref}
                        />
                      )}
                    />
                    {errors?.sendTo?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.sendTo.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>
              )}
              {accountBalance && accountBalance > 0 ? (
                <Grid
                  item
                  xs={6}
                  display={radiovalue === "new" ? "none" : "initial"}
                >
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Existing Balance
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox flexGrow={1} ml={2}>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      mt={1}
                    >
                      <SoftTypography fontSize={16}>
                        {formatCurrency(accountBalance)}
                      </SoftTypography>
                      <SoftTypography fontSize={12} ml={3} mr={1}>
                        Apply existing balance?
                      </SoftTypography>
                      <Checkbox
                        checked={applyAccountBalance}
                        onClick={handleApplyBalance}
                      />
                    </SoftBox>
                  </SoftBox>
                </Grid>
              ) : null}
            </Grid>
          </SoftBox>
          <SoftBox
            mt={2}
            display="flex"
            flexDirection="column"
            sx={{ border: "1px solid #d3d3d3", borderRadius: "10px" }}
          >
            <SoftBox>
              <Table
                columns={columns}
                rows={lineItems}
                onRowDelete={onRowDelete}
              />
            </SoftBox>
          </SoftBox>
          <SoftBox mt={2}>
            <LineItemModal handleCloseLineItemModal={handleLineItemChange} />
          </SoftBox>
          <SoftBox mt={2}>
            {applyAccountBalance && radiovalue != "new" ? (
              <SoftBox display="flex" justifyContent="end">
                <SoftBox>
                  <SoftTypography
                    variant="body1"
                    fontSize={16}
                    fontWeight="light"
                    color="text"
                  >
                    Account Credit:
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml={1}>
                  <SoftTypography
                    variant="body1"
                    fontWeight="medium"
                    fontSize={16}
                  >
                    <CurrencyFormat
                      value={Math.max(accountBalance * -1, totalAmount * -1)}
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator
                      prefix="$"
                    />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            ) : null}
            <SoftBox display="flex" justifyContent="end" mt={3}>
              <SoftBox>
                <SoftTypography variant="body1" fontWeight="light" color="text">
                  Total:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body1" fontWeight="medium">
                  <CurrencyFormat
                    value={
                      applyAccountBalance && radiovalue != "new"
                        ? Math.max(totalAmount - accountBalance, 0)
                        : totalAmount ?? 0
                    }
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox
            mt={3}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox display="flex" flexDirection="row">
              <SoftBox display="flex" alignItems="center" pr={3}>
                <Checkbox
                  defaultChecked={enableACH}
                  onChange={toggleACH}
                  disabled={!enableCC}
                />
                <SoftTypography variant="button" fontWeight="medium">
                  ACH
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" alignItems="center" pr={3}>
                <Checkbox
                  defaultChecked={enableCC}
                  onChange={toggleCC}
                  disabled={!enableACH}
                />
                <SoftTypography variant="button" fontWeight="medium">
                  Credit Card
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftButton
              disabled={isSubmitting || totalAmount <= 0}
              type="submit"
              color="primary"
            >
              Send
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

RequestPaymentModal.propTypes = {
  handleClose: PropTypes.func,
};
