// @mui material components
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

interface IOwnerStateProps {
  variant: "contained" | "gradient";
  color: string;
  value: number;
  size: "small" | "medium" | "large";
}

export default styled(LinearProgress)<{ ownerState: IOwnerStateProps }>(
  ({ theme, ownerState }) => {
    const { palette, functions } = theme as any;
    const { color, value, variant, size } = ownerState;

    const { text, gradients } = palette;
    const { linearGradient } = functions;

    const height =
      size === "large" ? "1.3rem" : size === "medium" ? "0.7rem" : "0.5rem";

    // background value
    let backgroundValue;

    if (variant === "gradient") {
      backgroundValue = gradients[color]
        ? linearGradient(gradients[color].main, gradients[color].state)
        : linearGradient(gradients.info.main, gradients.info.state);
    } else {
      backgroundValue = palette[color]
        ? palette[color].main
        : palette.info.main;
    }

    return {
      height,
      overflow: "visible",
      borderRadius: 0,
      "& .MuiLinearProgress-bar": {
        background: backgroundValue,
        width: `${value}%`,
        color: text.main,
        cursor: "pointer",
        height,
        borderRadius: 0,
      },
    };
  }
);
