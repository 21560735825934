import * as toastr from "toastr";
import { useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import SoftInput from "src/components/SoftInput";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { doc, updateDoc } from "firebase/firestore";
import { getClubsCollection } from "src/features/club/collections";

const schema = yup
  .object({
    title: yup.string().required("A description is required."),
    requireDetails: yup.boolean(),
  })
  .required();

export function AddCancellationReasonModal({ handleClose, reason }) {
  const [hasCost, setHasCost] = useState(reason?.cost && reason?.cost > 0);
  const handleSetHasCost = () => setHasCost(!hasCost);

  const { selectedClub, selectedClubId } = useClubs();
  const { preferences } = selectedClub;
  const reasons = preferences?.cancellationReasons || [];

  const updatePreference = async (key, value) => {
    preferences[key] = value;
    try {
      await updateDoc(doc(getClubsCollection(), selectedClubId), {
        preferences,
      });
    } catch (e) {
      console.error(e);
      toastr.error(
        "There was an error adding the cancellation reason.",
        "Error"
      );
    }
  };

  let defaultValues;
  if (reason) {
    defaultValues = {
      cost: reason.cost ? reason.cost : 0,
      title: reason.title,
      requireDetails: reason.requireDetails || false,
    };
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const newReason = {
      title: data.title,
      cost: data.cost || 0,
      requireDetails: data.requireDetails || false,
    };

    if (reason) {
      reasons.splice(
        reasons.findIndex((r) => r.title === reason.title),
        1,
        newReason
      );
    } else {
      reasons.push(newReason);
    }
    await updatePreference("cancellationReasons", reasons);
    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {reason ? "Edit Cancellation Reason" : "Add Cancellation Reason"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Description
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Eg. Weather"
                  {...register("title", {
                    required: true,
                  })}
                />
                {errors?.title?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.title.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={2}>
                <SoftBox ml={0.5} pt={5} display="inline-block">
                  <Checkbox
                    checked={hasCost}
                    onClick={handleSetHasCost}
                    {...register("hasCost", {
                      required: false,
                    })}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetHasCost}
                    sx={{
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    Has Cost?
                  </SoftTypography>
                </SoftBox>
              </Grid>
              {hasCost ? (
                <Grid item xs={12} sm={2}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Cost
                    </SoftTypography>
                  </SoftBox>
                  <Controller
                    control={control}
                    name="cost"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftCurrencyInput
                        currencySymbol="$"
                        placeholder="0.00"
                        outputFormat="number"
                        onBlur={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.cost?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.cost.message}
                    </SoftTypography>
                  )}
                </Grid>
              ) : null}
              <Grid item xs={12} display="flex">
                <SoftBox ml={0.5} pt={0} display="inline-block">
                  <Controller
                    control={control}
                    name="requireDetails"
                    render={({ field: { onChange, value, ref } }) => (
                      <>
                        <Checkbox
                          checked={value}
                          onChange={onChange}
                          inputRef={ref}
                        />
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          onClick={() => onChange(!value)}
                          sx={{
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                        >
                          Require Additional Details?
                        </SoftTypography>
                      </>
                    )}
                  />
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    Add
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddCancellationReasonModal.propTypes = {
  handleClose: PropTypes.func,
  reason: PropTypes.object,
};
