import { createContext, useContext, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import { useLms } from "src/features/lms/LmsProvider";
import { AuthLoadingPage } from "src/components/AuthLoadingPage";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "src/features/firebase/auth/utils";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import {
  getUsersCollection,
  getUserCheckoutsCollection,
} from "src/features/user/collections";
import {
  getClubBookingsCollection,
  getClubInstructorPreferencesCollection,
} from "src/features/club/collections";
import { getAircraftCollection } from "src/features/aircraft/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { isFuture, eachWeekendOfInterval, startOfToday } from "date-fns";
import { getBillingCollection } from "../billing/collections";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useUserRolesDocuments } from "src/hooks/useUserRolesDocuments";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";

const UserPermissionsContext = createContext(null);

UserPermissionsContext.displayName = "UserPermissionsContext";

function UserPermissionsProvider({ children }) {
  const { userId } = useUser();
  const {
    selectedLocationId,
    selectedClubId,
    selectedClub,
    userLocations,
    clubMemberships,
    memberHasNoClub,
    clubUsers,
    clubDocuments,
    clubUserRoles,
    locationReservationTypes,
  } = useClubs();
  const [userPermissions, setUserPermissions] = useState();
  const [isInstructor, setIsInstructor] = useState(false);
  const [savedUser] = useAuthState(auth);
  const { activeCourse } = useLms();
  const { getUserRolesDocuments } = useUserRolesDocuments();
  const { isPreFlightAdmin, hasAccess } = usePermissions();
  const [userReservationTypes, setUserReservationTypes] = useState(new Set());

  const updateUserReservationTypes = async () => {
    const userDocsSet = new Set();
    if (userPermissions?.isPreFlightAdmin) {
      locationReservationTypes.forEach((d) => {
        return userDocsSet.add(d);
      });
    } else {
      const userRoles = userPermissions?.userRoles ?? [];
      if (!userRoles) return;
      userRoles.forEach((roleId) => {
        const role = clubUserRoles.find((r) => r.id === roleId);

        if ((role?.configuration?.reservationTypes ?? []).length > 0)
          role?.configuration?.reservationTypes?.forEach((d) => {
            return (
              locationReservationTypes.get(d) &&
              userDocsSet.add(locationReservationTypes.get(d))
            );
          });
      });
    }
    setUserReservationTypes(userDocsSet);
  };

  useEffect(() => {
    if (!userId) return;
    updateUserReservationTypes();
  }, [userPermissions, locationReservationTypes, selectedLocationId]);

  useEffect(() => {
    if (memberHasNoClub) {
      if (isPreFlightAdmin) {
        console.log(
          "PreFlight Admin logged in, adding all clubs to userPermissions"
        );
      } else {
        console.log("Setting user has no club!!");
        setUserPermissions({});
      }
    }
  }, [memberHasNoClub]);

  const userMembershipsRef =
    userId && collection(getUsersCollection(), userId, "memberships");
  const { data: userMemberships, isDataLoaded: membershipsLoaded } =
    useRealtimeCollectionData(userMembershipsRef, true);

  useEffect(() => {
    let userCustomAttributes;
    if (savedUser && savedUser.reloadUserInfo?.customAttributes) {
      userCustomAttributes = JSON.parse(
        savedUser.reloadUserInfo.customAttributes
      );
    }
    if (userCustomAttributes && userCustomAttributes.superadmin) {
      setUserPermissions({
        isPreFlightAdmin: true,
      });
    } else {
      if (!userLocations || userLocations?.length === 0) return;
      if (selectedLocationId) {
        userLocations.forEach((userLocation) => {
          if (userLocation.id === selectedLocationId)
            setUserPermissions(userLocation.data());
        });
      }
    }
  }, [savedUser, userLocations, selectedLocationId]);

  useEffect(() => {
    if (!userId || !selectedClubId || !selectedLocationId) return;
    getDoc(
      doc(
        getClubInstructorPreferencesCollection(
          selectedClubId,
          selectedLocationId
        ),
        userId
      )
    ).then((instructorPrefsDoc) => {
      setIsInstructor(!!instructorPrefsDoc?.data());
    });
  }, [selectedClubId, selectedLocationId, userId]);

  const isUserCheckedOutOnAircraft = async (userId, aircraftId) => {
    if (userId) {
      const checkout = await getDoc(
        doc(getUserCheckoutsCollection(userId), aircraftId)
      );
      if (checkout?.data()) {
        if (checkout?.data()?.expires) {
          if (isFuture(checkout?.data()?.expires?.toDate())) return true;
        } else {
          return true;
        }
      }
    }

    return false;
  };

  const isUserInAircraftMembershipLevel = async (booking) => {
    if (
      booking?.extendedProps?.type?.value !== "reservation" &&
      booking?.extendedProps?.type?.value !== "checkride" &&
      booking?.extendedProps?.type?.value !== "checkout"
    )
      return { allowed: true };
    const pilotMemberships = (
      await getDocs(
        collection(
          getUsersCollection(),
          booking.extendedProps.pilot.value,
          "memberships"
        )
      )
    ).docs;
    if (
      hasAccess(
        [systemPermissions.BYPASS_MEMBERSHIP_REQUIREMENTS],
        booking.extendedProps.pilot.value
      )
    ) {
      return { allowed: true };
    }
    const result = { allowed: false };
    const aircraft = (
      await getDoc(
        doc(
          getAircraftCollection(selectedClubId),
          booking.extendedProps.aircraft.value
        )
      )
    ).data();
    let aircraftMemberships = aircraft?.restrictions?.memberships;
    if (!aircraftMemberships || aircraftMemberships.length === 0)
      return { allowed: true };
    if (typeof aircraftMemberships === "string") {
      aircraftMemberships = [aircraftMemberships];
    }
    aircraftMemberships.forEach((membership) => {
      if (
        pilotMemberships
          ?.map((pm) => pm.data().membershipPlan)
          .includes(membership)
      )
        result.allowed = true;
      else {
        const clubMembership = clubMemberships.find(
          (clubMembershipData) => clubMembershipData.id === membership
        );
        result.reason = result.reason
          ? `${result.reason}/${clubMembership.data().label}`
          : clubMembership.data().label;
      }
    });
    // if (!aircraft) return true;
    return result;
  };

  // const isUserDocumentsOnFile = () => {};

  // const isUserMembershipCurrent = () => {};

  // const isUserInRestrictedGroup = () => {};

  const isMaxBookingNumberExceeded = async (booking) => {
    const result = { allowed: true, reasons: [] };
    if (booking?.extendedProps?.type?.value !== "reservation") return result;

    const clubMaxBookings = selectedClub.preferences?.maxNumberOfBookings;
    const clubMaxWeekendBookings =
      selectedClub.preferences?.maxNumberOfWeekendBookings;

    if (clubMaxBookings && clubMaxBookings > 0) {
      const clubsBookingsCountRef = query(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        where("extendedProps.type.value", "==", "reservation"),
        where("extendedProps.pilot.value", "==", userId),
        where("cancelled", "==", false),
        where("completed", "==", false),
        where("end", ">=", startOfToday())
      );

      const bookings = await getDocs(clubsBookingsCountRef);
      const bookingIds = bookings.docs.map((bookingDoc) => bookingDoc.id);

      if (
        !bookingIds.includes(booking.id) &&
        bookings.docs.length >= clubMaxBookings
      ) {
        result.allowed = false;
        result.reasons.push(
          `Maximum number of bookings (${clubMaxBookings}) exceeded`
        );
      }

      const bookingWeekendDays = eachWeekendOfInterval({
        start:
          booking.start instanceof Date
            ? booking.start
            : booking.start.toDate(),
        end: booking.end instanceof Date ? booking.end : booking.end.toDate(),
      });
      if (bookingWeekendDays.length > 0) {
        let numberOfWeekendBookings = 0;
        bookings.docs.forEach((bookingDoc) => {
          const weekendDays = eachWeekendOfInterval({
            start: bookingDoc.data().start.toDate(),
            end: bookingDoc.data().end.toDate(),
          });
          if (weekendDays.length > 0) {
            numberOfWeekendBookings += 1;
          }
        });
        if (numberOfWeekendBookings >= clubMaxWeekendBookings) {
          const existingBooking = bookings.docs.find(
            (bookingDoc) => bookingDoc.id === booking.id
          );
          if (existingBooking) {
            const existingBookingWeekendDays = eachWeekendOfInterval({
              start: existingBooking.data().start.toDate(),
              end: existingBooking.data().end.toDate(),
            });
            if (existingBookingWeekendDays.length === 0) {
              result.allowed = false;
              result.reasons.push(
                `Maximum number of weekend bookings (${clubMaxWeekendBookings} max) reached`
              );
            }
          } else {
            result.allowed = false;
            result.reasons.push(
              `Maximum number of weekend bookings (${clubMaxWeekendBookings} max) reached`
            );
          }
        }
      }
    }

    return result;
  };

  const isClubDisabled = async (booking) => {
    const result = { allowed: true, reasons: [] };
    if (booking?.extendedProps?.type?.value !== "reservation") return result;

    const clubMembersCanBook =
      selectedClub.preferences?.calendar?.membersCanBook;
    const clubStudentsCanBook =
      selectedClub.preferences?.calendar?.studentsCanBook;

    if (activeCourse) {
      if (!clubStudentsCanBook) {
        result.allowed = false;
        result.reasons.push(
          `Students are not allowed to book, please contact the club to make a reservation`
        );
      }
    } else if (!clubMembersCanBook) {
      result.allowed = false;
      result.reasons.push(
        `Members are not allowed to book, please contact the club to make a reservation`
      );
    }

    return result;
  };

  const bookingRequiresPayment = (booking) => {
    const bookingType = booking?.extendedProps?.type?.value;
    const disableCheckoutPayments =
      selectedClub.preferences?.disableCheckoutPayments;

    return disableCheckoutPayments
      ? bookingType === "reservation" ||
          bookingType === "checkride" ||
          bookingType === "solo" ||
          bookingType === "rideShare" ||
          bookingType === "rental" ||
          bookingType === "pilotRefresher"
      : bookingType === "checkout" ||
          bookingType === "reservation" ||
          bookingType === "solo" ||
          bookingType === "rideShare" ||
          bookingType === "rental" ||
          bookingType === "pilotRefresher" ||
          bookingType === "checkride";
  };

  const hasPendingPayment = async (userId) => {
    const functions = getFunctions();
    const getPayments = httpsCallable(functions, "getPayments");

    const getPendingPaymentsResult = await getPayments({ userId });

    if (getPendingPaymentsResult?.data) {
      const payments = getPendingPaymentsResult.data.data.filter(
        (payment) =>
          payment.status !== "requires_payment_method" &&
          payment.status !== "succeeded"
      );
      if (payments.length > 0) {
        return true;
      }
    }
    return false;
  };

  const canCreateBooking = async (booking) => {
    const response = { allowed: true, reasons: [] };

    const pilot = booking.extendedProps?.pilot;
    const pilot2 = booking.extendedProps?.pilot2;

    if (pilot && pilot.value !== "") {
      const { hasAllDocs, missingDocs } = await getUserRolesDocuments(
        pilot.value
      );
      if (!hasAllDocs) {
        missingDocs.forEach((docId) => {
          const doc = clubDocuments.find((cd) => cd.id === docId);
          if (doc) {
            response.allowed = false;
            response.reasons.push(
              `${pilot.label}: Missing required document ${doc.name}`
            );
          }
        });
      }
    }
    if (pilot2 && pilot2.value !== "") {
      const { hasAllDocs, missingDocs } = await getUserRolesDocuments(
        pilot2.value
      );
      if (!hasAllDocs) {
        missingDocs.forEach((docId) => {
          const doc = clubDocuments.find((cd) => cd.id === docId);
          if (doc) {
            response.allowed = false;
            response.reasons.push(
              `${pilot2.label}: Missing required document ${doc.name}`
            );
          }
        });
      }
    }

    if (
      bookingRequiresPayment(booking) &&
      selectedClub.preferences?.requireCardOnFile === true
    ) {
      if (pilot && pilot.value !== "") {
        const pilotUser = clubUsers.find((user) => user.id === pilot.value);

        const defaultCard = pilotUser?.defaultCard;
        if (!defaultCard) {
          response.allowed = false;
          response.reasons.push(`${pilot.label}: No card on file`);
        }
      }
      if (pilot2 && pilot2.value !== "") {
        const pilotUser = clubUsers.find((user) => user.id === pilot2.value);

        const defaultCard = pilotUser?.defaultCard;
        if (!defaultCard) {
          response.allowed = false;
          response.reasons.push(`${pilot2.label}: No card on file`);
        }
      }
    }
    if (
      bookingRequiresPayment(booking) &&
      selectedClub.preferences?.bookWithUnpaidInvoice !== true
    ) {
      const clubId = booking.extendedProps?.clubId;
      if (pilot && pilot.value !== "" && clubId) {
        const pilotBalanceSnap = await getDoc(
          doc(getBillingCollection(), pilot.value)
        );
        const pilotBalance = pilotBalanceSnap.data()?.[clubId];
        if (pilotBalance && pilotBalance < 0) {
          if (
            selectedClub.preferences?.bookWithPendingPayment === true &&
            (await hasPendingPayment(pilot.value))
          ) {
            return response;
          }
          response.allowed = false;
          response.reasons.push(`${pilot.label}: Negative account balance`);
        }
      }
      if (pilot2 && pilot2.value !== "" && clubId) {
        const pilotBalanceSnap = await getDoc(
          doc(getBillingCollection(), pilot2.value)
        );
        const pilotBalance = pilotBalanceSnap.data()?.[clubId];
        if (pilotBalance && pilotBalance < 0) {
          if (
            selectedClub.preferences?.bookWithPendingPayment === true &&
            (await hasPendingPayment(pilot2.value))
          ) {
            return response;
          }
          response.allowed = false;
          response.reasons.push(`${pilot2.label}: Negative account balance`);
        }
      }
    }
    if (hasAccess([systemPermissions.CREATE_BOOKING])) {
      const pilot = booking.extendedProps?.pilot;
      const pilot2 = booking.extendedProps?.pilot2;
      const aircraft = booking.extendedProps?.aircraft;
      if (pilot?.value === savedUser.uid || pilot2?.value === savedUser.uid) {
        const isClubDisabledResponse = await isClubDisabled(booking);
        if (!isClubDisabledResponse.allowed) {
          response.allowed = false;
          response.reasons = response.reasons.concat(
            isClubDisabledResponse.reasons
          );
          return response;
        }

        if (booking.extendedProps.instructor?.value !== "") {
          response.allowed = true;
          return response;
        }

        if (selectedClub.preferences?.requireAircraftCheckout === true) {
          if (pilot && pilot.value !== "") {
            const checkedOut = await isUserCheckedOutOnAircraft(
              pilot.value,
              aircraft.value
            );
            if (!checkedOut) {
              response.allowed = false;
              response.reasons.push(
                `${pilot.label}: Not checked out in this aircraft`
              );
            }
          }
          if (pilot2 && pilot2.value !== "") {
            const checkedOut = await isUserCheckedOutOnAircraft(
              pilot2.value,
              aircraft.value
            );
            if (!checkedOut) {
              response.allowed = false;
              response.reasons.push(
                `${pilot2.label}: Not checked out in this aircraft`
              );
            }
          }
        }

        const maxBookings = await isMaxBookingNumberExceeded(booking);
        if (!maxBookings.allowed) {
          response.allowed = false;
          response.reasons = response.reasons.concat(maxBookings.reasons);
        }

        return response;
      }
    } else {
      if (!booking.id) {
        response.allowed = false;
        response.reasons.push(
          "You do not have permission to create bookings, please contact us to join!"
        );
      } else if (!booking.extendedProps?.instructor?.value) {
        response.allowed = false;
        response.reasons.push(
          "You are only allowed to modify bookings with an instructor added"
        );
      }

      return response;
    }

    return response;
  };

  const canDispatchBooking = async (booking) => {
    const response = { allowed: true, reasons: [] };

    const pilot = booking.extendedProps?.pilot;
    const pilot2 = booking.extendedProps?.pilot2;

    if (pilot && pilot.value !== "") {
      const { hasAllDocs, missingDocs } = await getUserRolesDocuments(
        pilot.value
      );
      if (!hasAllDocs) {
        missingDocs.forEach((docId) => {
          const doc = clubDocuments.find((cd) => cd.id === docId);
          if (doc) {
            response.allowed = false;
            response.reasons.push(`Missing required document ${doc.name}`);
          }
        });
      }
    }

    if (pilot2 && pilot2.value !== "") {
      const { hasAllDocs, missingDocs } = await getUserRolesDocuments(
        pilot2.value
      );
      if (!hasAllDocs) {
        missingDocs.forEach((docId) => {
          const doc = clubDocuments.find((cd) => cd.id === docId);
          if (doc) {
            response.allowed = false;
            response.reasons.push(`Missing required document ${doc.name}`);
          }
        });
      }
    }

    if (
      bookingRequiresPayment(booking) &&
      selectedClub.preferences?.requireCardOnFile === true
    ) {
      if (pilot && pilot.value !== "") {
        const pilotUser = clubUsers.find((user) => user.id === pilot.value);

        const defaultCard = pilotUser?.defaultCard;
        if (!defaultCard) {
          response.allowed = false;
          response.reasons.push(`${pilot.label}: No card on file`);
        }
      }
      if (pilot2 && pilot2.value !== "") {
        const pilotUser = clubUsers.find((user) => user.id === pilot2.value);

        const defaultCard = pilotUser?.defaultCard;
        if (!defaultCard) {
          response.allowed = false;
          response.reasons.push(`${pilot2.label}: No card on file`);
        }
      }
    }

    if (
      bookingRequiresPayment(booking) &&
      selectedClub.preferences?.bookWithUnpaidInvoice !== true
    ) {
      const clubId = booking.extendedProps?.clubId;
      if (pilot && pilot.value !== "" && clubId) {
        const pilotBalanceSnap = await getDoc(
          doc(getBillingCollection(), pilot.value)
        );
        const pilotBalance = pilotBalanceSnap.data()?.[clubId];
        if (pilotBalance && pilotBalance < 0) {
          if (
            selectedClub.preferences?.bookWithPendingPayment === true &&
            (await hasPendingPayment(pilot.value))
          ) {
            return response;
          }
          response.allowed = false;
          response.reasons.push(`${pilot.label}: Negative account balance`);
        }
      }
      if (pilot2 && pilot2.value !== "" && clubId) {
        const pilotBalanceSnap = await getDoc(
          doc(getBillingCollection(), pilot2.value)
        );
        const pilotBalance = pilotBalanceSnap.data()?.[clubId];
        if (pilotBalance && pilotBalance < 0) {
          if (
            selectedClub.preferences?.bookWithPendingPayment === true &&
            (await hasPendingPayment(pilot.value))
          ) {
            return response;
          }
          response.allowed = false;
          response.reasons.push(`${pilot2.label}: Negative account balance`);
        }
      }
    }
    if (
      hasAccess([
        systemPermissions.CHECK_OUT_OWN_BOOKING,
        systemPermissions.CHECK_OUT_OTHERS_BOOKING,
      ])
    ) {
      if (
        booking.extendedProps.pilot?.value === savedUser.uid ||
        booking.extendedProps.pilot2?.value === savedUser.uid
      ) {
        if (booking.extendedProps.instructor?.value !== "") {
          response.allowed = true;
          return response;
        }

        if (
          pilot?.value &&
          selectedClub.preferences?.requireAircraftCheckout === true &&
          !isUserCheckedOutOnAircraft(
            pilot.value,
            booking.extendedProps.aircraft?.value
          )
        ) {
          response.allowed = false;
          response.reasons.push(`${pilot.label}: Not checked out in aircraft`);
        }
        if (
          pilot2?.value &&
          selectedClub.preferences?.requireAircraftCheckout === true &&
          !isUserCheckedOutOnAircraft(
            pilot2.value,
            booking.extendedProps.aircraft?.value
          )
        ) {
          response.allowed = false;
          response.reasons.push(`${pilot2.label}: Not checked out in aircraft`);
        }

        const maxBookings = await isMaxBookingNumberExceeded(booking);
        if (!maxBookings.allowed) {
          response.allowed = false;
          response.reasons = response.reasons.concat(maxBookings.reasons);
        }
      }
    } else {
      response.allowed = false;
      response.reasons.push("You are not allowed to dispatch bookings");
    }

    return response;
  };

  const canOverrideBooking = (booking) => {
    return (
      hasAccess(systemPermissions.OVERRIDE_BOOKING_REQUIREMENTS) &&
      booking.extendedProps.clubId === selectedClubId
    );
  };

  const canEditBooking = (booking) => {
    if (hasAccess(systemPermissions.PREFLIGHT_ADMIN)) return true;
    if (booking.completed || booking.cancelled || booking.dispatched)
      return false;

    if (hasAccess(systemPermissions.MODIFY_OTHERS_BOOKING)) return true;
    if (
      userId === booking.extendedProps.pilot?.value ||
      userId === booking.extendedProps.pilot2?.value ||
      userId === booking.extendedProps.instructor?.value
    ) {
      return hasAccess(systemPermissions.MODIFY_OWN_BOOKING);
    }

    return false;
  };

  const canViewBookingDetails = (booking) => {
    if (hasAccess(systemPermissions.VIEW_OTHERS_BOOKING_DETAILS)) return true;
    if (
      userId === booking.extendedProps.pilot?.value ||
      userId === booking.extendedProps.pilot2?.value ||
      userId === booking.extendedProps.instructor?.value
    ) {
      return hasAccess(systemPermissions.VIEW_OWN_BOOKING_DETAILS);
    }

    return false;
  };

  const canViewInternalInformation = () => {
    if (hasAccess([systemPermissions.VIEW_INTERNAL_NOTES])) return true;

    return false;
  };

  const value = {
    userPermissions,
    userMemberships,
    membershipsLoaded,
    userReservationTypes,
    canCreateBooking,
    canDispatchBooking,
    isClubDisabled,
    canEditBooking,
    canViewBookingDetails,
    canViewInternalInformation,
    canOverrideBooking,
    isUserInAircraftMembershipLevel,
    isMaxBookingNumberExceeded,
    isInstructor,
  };
  const hasClubWithNoPermissions = selectedClubId && !selectedLocationId;
  const memberHasClubWithNoPermissions =
    !memberHasNoClub && hasClubWithNoPermissions;

  if (
    (memberHasClubWithNoPermissions ||
      (selectedClubId && !userPermissions) ||
      !membershipsLoaded) &&
    userId
  ) {
    return <AuthLoadingPage text="Please wait while we load your data." />;
  }

  return (
    <UserPermissionsContext.Provider value={value}>
      {children}
    </UserPermissionsContext.Provider>
  );
}

function useUserPermissions() {
  const context = useContext(UserPermissionsContext);

  if (!context) {
    throw new Error(
      "useUserPermissions should be used inside the UserPermissionsProvider."
    );
  }

  return context;
}

UserPermissionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserPermissionsProvider, useUserPermissions };
