// Soft UI Dashboard PRO React components
import SoftTypography from "src/components/SoftTypography";

// Custom styles for SoftProgress
import DataProgressRoot from "src/components/DataProgress/DataProgressRoot";
import SoftBox from "src/components/SoftBox";
import { useMemo } from "react";

interface IProgressPaceBarProps {
  variant?: "contained" | "gradient";
  color?: string;
  value: number;
  sx?: any;
  size?: "small" | "medium" | "large";
}

const ProgressPaceBar = ({
  variant = "contained",
  color = "primary",
  value,
  sx = {},
  size = "small",
}: IProgressPaceBarProps) => {

  const colorString = useMemo(() => {
    if (value < 40) {
      return "error";
    } else if (value > 60) {
      return "success";
    } else {
      return color;
    }
  }, [value]);

  return (
    <SoftBox sx={{ ...sx }}>
      <DataProgressRoot
        variant="determinate"
        value={value}
        ownerState={{
          color: colorString,
          value: value,
          variant,
          size,
        }}
      />
      <SoftBox display="flex" justifyContent="space-between">
        <SoftTypography
          fontSize="small"
          fontWeight="regular"
          lineHeight={1.1}
          color={colorString}
        >
          |
        </SoftTypography>
        <SoftTypography
          fontSize="small"
          fontWeight="regular"
          lineHeight={1.1}
          color={value >= 50 ? colorString : "secondary"}
        >
          |
        </SoftTypography>
        <SoftTypography
          fontSize="small"
          fontWeight="regular"
          lineHeight={1.1}
          color={value === 100 ? colorString : "secondary"}
        >
          |
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between">
        <SoftTypography
          fontSize="small"
          fontWeight="regular"
          color={colorString === "error" ? colorString : "secondary"}
        >
          Behind
        </SoftTypography>
        <SoftTypography
          fontSize="small"
          fontWeight="regular"
          color={colorString === color ? color : "secondary"}
        >
          On track
        </SoftTypography>
        <SoftTypography
          fontSize="small"
          fontWeight="regular"
          color={colorString === "success" ? colorString : "secondary"}
        >
          Ahead
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

export default ProgressPaceBar;
