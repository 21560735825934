import { collectionGroup, collection } from "firebase/firestore";
import { db } from "src/features/firebase/firestore/utils";

const getLocationsCollectionGroup = () => collectionGroup(db, "locations");

const getLocationReservationTypesCollection = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/reservationTypes`);

const getGroupsCollection = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/groups`);

export {
  getLocationsCollectionGroup,
  getLocationReservationTypesCollection,
  getGroupsCollection,
};
