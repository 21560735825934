import { Card, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import DataTable from "src/components/Tables/DataTable";
import MiniStatisticsCard from "src/containers/Cards/StatisticsCards/MiniStatisticsCard";
import DashboardNavbar from "src/containers/DashboardNavbar";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import Footer from "src/components/Footer";
import DefaultCell from "src/pages/reports/components/DefaultTableCell";
import { format } from "date-fns";
import { useClubs } from "src/features/club/ClubProvider";
import { useLms } from "src/features/lms/LmsProvider";
import { where } from "firebase/firestore";
import ProgressPaceBar from "src/components/ProgressPaceBar";

const columns = [
  {
    Header: "Student",
    accessor: "member.label",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Course",
    accessor: "course.label",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Instructor",
    accessor: "instructor.label",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "start date",
    accessor: "startDate",
    Cell: ({ value }) =>
      value ? <DefaultCell value={format(value?.toDate(), "PP")} /> : null,
  },
  {
    Header: "end date",
    accessor: "completionDate",
    Cell: ({ value }) =>
      value ? <DefaultCell value={format(value?.toDate(), "PP")} /> : null,
  },
  {
    Header: "Progress",
    accessor: ({ progress }) => {
      if (progress.pace > 100) {
        return 100;
      }
      if (progress.pace < 1) {
        return 1;
      }
      return progress.pace;
    },
    Cell: ({ value }) =>
      value ? (
        <ProgressPaceBar value={value} sx={{ mr: 1 }} variant="gradient" />
      ) : null,
  },
];

export default function StudentProgressReport() {
  const { selectedLocationId } = useClubs();
  const { getClubEnrollmentsData } = useLms();
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [studentTotals, setStudentTotals] = useState({});

  const getEnrolledCourses = async () => {
    const enrollments = await getClubEnrollmentsData(selectedLocationId, null, [
      where("status", "==", "IN_PROGRESS"),
    ]);
    const enrolledCoursesData = enrollments.flatMap(
      (enrollment) => enrollment.enrolledCourses
    );
    const totalCounts = {
      ahead: 0,
      behind: 0,
      onTrack: 0,
      total: enrolledCoursesData.length,
    };
    enrolledCoursesData.forEach(({ progress }) => {
      if (progress.pace > 60) {
        totalCounts.ahead += 1;
      } else if (progress.pace > 40) {
        totalCounts.onTrack += 1;
      } else {
        totalCounts.behind += 1;
      }
    });
    setStudentTotals(totalCounts);
    setEnrolledCourses(enrolledCoursesData);
    setIsLoading(false);
  };

  const percentageOfTotal = (num, total) => {
    return (Math.round((num / total) * 100) || 0) + " %";
  };

  useEffect(() => {
    getEnrolledCourses();
  }, [selectedLocationId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <SoftBox display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </SoftBox>
      ) : (
        <SoftBox>
          <SoftBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <MiniStatisticsCard
                  title={{
                    text: "Ahead",
                    fontWeight: "medium",
                  }}
                  count={studentTotals.ahead + " Students"}
                  icon={{
                    color: "success",
                    component: percentageOfTotal(
                      studentTotals.ahead,
                      studentTotals.total
                    ),
                    isCustom: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MiniStatisticsCard
                  title={{
                    text: "On Track",
                    fontWeight: "medium",
                  }}
                  count={studentTotals.onTrack + " Students"}
                  icon={{
                    color: "primary",
                    component: percentageOfTotal(
                      studentTotals.onTrack,
                      studentTotals.total
                    ),
                    isCustom: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MiniStatisticsCard
                  title={{
                    text: "Behind",
                    fontWeight: "medium",
                  }}
                  count={studentTotals.behind + " Students"}
                  icon={{
                    color: "error",
                    component: percentageOfTotal(
                      studentTotals.behind,
                      studentTotals.total
                    ),
                    isCustom: true,
                  }}
                />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox mb={3}>
            <Card>
              <DataTable
                table={{ columns, rows: enrolledCourses }}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [10, 20, 50, 100],
                }}
                canSearch
              />
            </Card>
          </SoftBox>
        </SoftBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}
