import { useMemo, useState } from "react";
import Swal from "sweetalert2";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";

import { getTableColumns, TableActions } from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";

import { AddReservationTypeModal } from "src/modals/AddReservationTypeModal/AddReservationTypeModal";
import { getLocationReservationTypesCollection } from "src/features/location/collections";
import { IUseClub } from "src/interfaces";

function ReservationTypes() {
  const { deleteData } = entityCrudUtils();
  const {
    selectedClubId,
    selectedLocationId,
    locationReservationTypes,
  }: IUseClub = useClubs();
  const [selectedReservationType, setSelectedReservationType] =
    useState<any>(null);
  const [openAddReservationTypeModal, setOpenAddReservationTypeModal] =
    useState(false);

  const deleteReservationType = (reservationTypeId: any) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteData({
            entity: getLocationReservationTypesCollection(
              selectedClubId,
              selectedLocationId
            ),
            pathSegmentsArr: [reservationTypeId],
          });
        }
      });
  };

  const handleTableActions = (action: any, reservationType: any) => {
    switch (action) {
      case TableActions.DELETE:
        deleteReservationType(reservationType.id);
        break;
      case TableActions.EDIT:
        setSelectedReservationType(reservationType);
        setOpenAddReservationTypeModal(true);
        break;

      default:
        break;
    }
  };

  const handleAddReservationTypeModalClose = () => {
    setOpenAddReservationTypeModal(false);
    setSelectedReservationType(null);
  };

  const tableColumns = useMemo(
    () => getTableColumns(handleTableActions),
    [locationReservationTypes]
  );

  return (
    <Card
      id="reservation-types"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Reservation Types</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Configure the custom reservation types available to users.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <DataTable
            selectedRowId={false}
            canDrag={false}
            showTotalEntries={false}
            isSorted={false}
            canSearch={false}
            entriesPerPage={false}
            table={{
              columns: tableColumns,
              rows: Array.from(locationReservationTypes.values())?.filter(
                (doc: any) => !doc?.isSystemType
              ),
            }}
          />
        </SoftBox>
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              onClick={() => setOpenAddReservationTypeModal(true)}
            >
              Add Reservation Type
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddReservationTypeModal}
        onClose={handleAddReservationTypeModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddReservationTypeModal
            handleClose={handleAddReservationTypeModalClose}
            reservationType={selectedReservationType}
          />
        </SoftBox>
      </Modal>
    </Card>
  );
}

export default ReservationTypes;
