import { useEffect, useState } from "react";
import toastr from "toastr";
import PropTypes from "prop-types";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import SoftInput from "src/components/SoftInput";
import { useClubs } from "src/features/club/ClubProvider";
import useRfidScanner from "src/hooks/useRfidScanner";
import {
  deleteField,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { getUsersCollection } from "src/features/user/collections";

interface IAddBadgeModal {
  handleClose: (badgeNo?: string) => void;
  userId: string;
}

function AddBadgeModal({ handleClose, userId }: IAddBadgeModal) {
  const [newBadgeNumber, setNewBadgeNumber] = useState<string | null>(null);
  const {
    globalUsers,
    globalUsersSnapReload,
  }: { globalUsers: Map<string, any>; globalUsersSnapReload: () => void } =
    useClubs();

  const user = globalUsers.get(userId);

  useEffect(() => {
    if (user?.rfid) setNewBadgeNumber(user.rfid);
  }, [user]);

  const addBadge = async (badgeNo: string) => {
    if (!badgeNo) {
      await setDoc(
        doc(getUsersCollection(), userId),
        { rfid: deleteField() },
        { merge: true }
      );
      globalUsersSnapReload();
      handleClose("");
      return;
    }
    if (badgeNo === user?.rfid) {
      handleClose();
      return;
    }
    const existingRfid = await getDocs(
      query(getUsersCollection(), where("rfid", "==", badgeNo))
    );
    if (!existingRfid.empty) {
      console.log(
        "Badge already exists",
        existingRfid.docs.map((doc) => doc.data())
      );
      toastr.error("Badge already exists for another user");
      return;
    }
    // Add badge to user
    console.log("Add badge to user", badgeNo);
    await setDoc(
      doc(getUsersCollection(), userId),
      { rfid: badgeNo },
      { merge: true }
    );
    globalUsersSnapReload();
    handleClose(badgeNo);
  };

  const onRfidScanned = async (rfid: string) => {
    addBadge(rfid);
  };

  const { setEnabled } = useRfidScanner(onRfidScanned);

  useEffect(() => {
    setEnabled(true);
    return () => {
      setEnabled(false);
    };
  }, []);

  return (
    <ModalContainer handleClose={() => handleClose()}>
      <SoftTypography variant="h4">Add Badge</SoftTypography>
      <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }}>
        <Grid item xs={12}>
          <Divider sx={{ marginTop: 2, marginBottom: 0 }} />
        </Grid>

        <Grid item xs={3}>
          <SoftBox display="flex" flexDirection="column" alignItems="center">
            <SoftTypography variant="caption" fontWeight="bold" margin={0}>
              To User
            </SoftTypography>
            <SoftTypography
              bg="secondary"
              fontWeight="regular"
              fontSize="small"
            >
              {user?.displayName}
            </SoftTypography>
          </SoftBox>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ marginTop: 0, marginBottom: 0 }} />
        </Grid>

        <Grid item xs={12}>
          <SoftBox>
            <SoftTypography variant="caption" fontWeight="bold" margin={0}>
              Badge Number
            </SoftTypography>
            <SoftInput
              value={newBadgeNumber}
              onChange={(event) => setNewBadgeNumber(event.target.value)}
            />
          </SoftBox>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ margin: 1 }} />
        </Grid>

        <Grid item xs={12}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            lineHeight={1}
          >
            <SoftBox mb={1}>
              <SoftButton
                variant="outlined"
                color="dark"
                fullWidth
                onClick={() => handleClose()}
              >
                Cancel
              </SoftButton>
            </SoftBox>
            <SoftBox mb={1}>
              <SoftButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => addBadge(newBadgeNumber || "")}
              >
                {user?.rfid ? "Update" : "Add"}
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </ModalContainer>
  );
}

AddBadgeModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  badgeNo: PropTypes.string,
};

export default AddBadgeModal;
