import { Checkbox, Stack } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { useClubs } from "src/features/club/ClubProvider";
import { IUseClub } from "src/interfaces";

interface ReservationTypesProps {
  selectedReservationTypes: string[];
  setReservationTypes: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ReservationTypes = ({
  selectedReservationTypes,
  setReservationTypes,
}: ReservationTypesProps) => {
  const { locationReservationTypes } = useClubs() as IUseClub;

  return (
    <>
      <SoftTypography variant="h6" color="primary" my="16px">
        Reservation Types
      </SoftTypography>
      <Stack direction="column" gap="5px">
        {Array.from(locationReservationTypes?.values())?.map((doc: any) => (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{ padding: "5px 0" }}
            ml={3}
            key={doc.id}
          >
            <Checkbox
              value={doc.id}
              checked={selectedReservationTypes?.includes(doc.id)}
              onChange={(e) => {
                setReservationTypes((prev: any) => {
                  if (e.target.checked) return prev.concat(doc.id);
                  return prev.filter((d: any) => d !== doc.id);
                });
              }}
            />

            <SoftTypography variant="button">{doc.label}</SoftTypography>
          </SoftBox>
        ))}
      </Stack>
    </>
  );
};
