import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import PropTypes from "prop-types";

import { getFunctions, httpsCallable } from "firebase/functions";
import { useClubs } from "src/features/club/ClubProvider";

import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPaymentDetails,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

export function StripePaymentModal({ handleClose, transferId }) {
  const { selectedClubId } = useClubs();
  const functions = getFunctions();
  const [transfer, setTransfer] = useState(null);

  useEffect(() => {
    const getStripeTransfer = httpsCallable(functions, "getStripeTransfer");
    getStripeTransfer({
      clubId: selectedClubId,
      transferId: transferId,
    }).then((result) => {
      setTransfer(result.data);
    });
  }, [selectedClubId, transferId]);

  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      const getStripeAccountSession = httpsCallable(
        functions,
        "getStripeAccountSession"
      );
      const stripeAccountSession = await getStripeAccountSession({
        clubId: selectedClubId,
      });

      if (stripeAccountSession?.data) {
        // document.querySelector("#error").setAttribute("hidden", "");
        return stripeAccountSession?.data?.client_secret;
      } else {
        // Handle errors on the client side here
        // console.error("An error occurred");
        // document.querySelector("#error").removeAttribute("hidden");
        return undefined;
      }
    };

    return loadConnectAndInitialize({
      // This is a placeholder - it should be replaced with your publishable API key.
      // Sign in to see your own test API key embedded in code samples.
      // Don’t submit any personally identifiable information in requests made with this key.
      publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
      fetchClientSecret: fetchClientSecret,
    });
  });

  return (
    <SoftBox>
      {transfer && (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectPaymentDetails
            payment={transfer?.destination_payment}
            onClose={handleClose}
          />
        </ConnectComponentsProvider>
      )}
    </SoftBox>
  );
}

StripePaymentModal.propTypes = {
  handleClose: PropTypes.func,
  transferId: PropTypes.string.isRequired,
};
