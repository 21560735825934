import Slider from "react-slick";
import SoftBox from "../SoftBox";

export default function SoftSlider({ children, sx, settings }) {
  const defaultSettings = {
    dots: false,
    infinite: false,
    variableWidth: true,
    swipeToSlide: true,
    arrows: false,
    adaptiveHeight: true,
  };

  const sliderSettings = {...defaultSettings, ...settings }

  return (
    <SoftBox sx={sx}>
        <Slider {...sliderSettings}>
          {children}
        </Slider>
    </SoftBox>
  );
}