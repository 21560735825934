import { INote } from "../interface";
import { useClubs } from "src/features/club/ClubProvider";
import { IUseClub } from "src/interfaces";
import SoftBox from "src/components/SoftBox";
import SoftAvatar from "src/components/SoftAvatar";
import SoftTypography from "src/components/SoftTypography";
import { intlFormatDistance, format } from "date-fns";
import parse from "html-react-parser";
import colors from "src/assets/theme/base/colors";
import { Box, styled } from "@mui/material";
import SoftBadge from "src/components/SoftBadge";

const { light } = colors;

interface ISingleNoteProps {
  note: INote;
  onBookingNoteClick?: (bookingId: string) => void;
  withCalendarDate?: boolean;
}

const StyledContentBox = styled(Box)(() => ({
  fontSize: "14px",
  "& p": {
    marginBottom: 0,
  },
}));

export default function SingleNote({
  note,
  onBookingNoteClick,
  withCalendarDate,
}: ISingleNoteProps) {
  const { globalUsers } = useClubs() as IUseClub;
  const author = globalUsers.get(note.createdBy);

  const handleNoteClick = () => {
    if (note.sourceType == "booking" && onBookingNoteClick) {
      onBookingNoteClick(note.sourceId);
    }
  };

  return (
    <SoftBox
      display="flex"
      onClick={handleNoteClick}
      sx={{
        cursor:
          note.sourceType == "booking" && onBookingNoteClick
            ? "pointer"
            : "auto",
        p: 1,
        "&:hover": { backgroundColor: light.main },
      }}
    >
      <SoftBox display="flex" flexShrink={0} alignItems="flex-start">
        <SoftAvatar
          src={author.photoURL}
          alt={author.displayName}
          shadow="xs"
          bgColor="light"
          size="sm"
        />
      </SoftBox>
      <SoftBox flexGrow={1} ml={2}>
        <SoftBox display="inline-flex" alignItems="center">
          <SoftTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
            lineHeight="1rem"
          >
            {author.displayName}
          </SoftTypography>

          <SoftTypography
            variant="overline"
            fontWeight="light"
            opacity={0.6}
            ml={2}
          >
            {withCalendarDate
              ? format(note.createdAt.toDate(), "PPp")
              : intlFormatDistance(
                  note.createdAt instanceof Date
                    ? note.createdAt
                    : note.createdAt.toDate(),
                  new Date()
                )}
          </SoftTypography>
          {note.internal && (
            <SoftBadge badgeContent="internal" size="sm" color="secondary" />
          )}
        </SoftBox>
        <SoftBox mt={0.2} lineHeight={0.75}>
          <StyledContentBox>{parse(note.contentHTML || "")}</StyledContentBox>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}
