import { useMemo, useState } from "react";
import Swal from "sweetalert2";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubSquawkStatusesCollection } from "src/features/club/collections";

import { getTableColumns, TableActions } from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";

import { IUseClub } from "src/interfaces";
import { AddSquawkStatusModal } from "src/modals/AddSquawkStatusModal/AddSquawkStatusModal";

function SquawkStatuses() {
  const { deleteData } = entityCrudUtils();
  const { selectedClubId, squawkStatuses }: IUseClub = useClubs();
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [openAddSquawkStatusModal, setOpenAddSquawkStatusModal] =
    useState(false);

  const deleteStatus = (status: any) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteData({
            entity: getClubSquawkStatusesCollection(selectedClubId),
            pathSegmentsArr: [status.id],
          });
        }
      });
  };

  const handleTableActions = (action: any, status: any) => {
    switch (action) {
      case TableActions.DELETE:
        deleteStatus(status);
        break;
      case TableActions.EDIT:
        setSelectedStatus(status);
        setOpenAddSquawkStatusModal(true);
        break;

      default:
        break;
    }
  };

  const handleAddSquawkStatusModalClose = () => {
    setOpenAddSquawkStatusModal(false);
    setSelectedStatus(null);
  };

  const tableColumns = useMemo(
    () => getTableColumns(handleTableActions),
    [squawkStatuses]
  );

  return (
    <Card
      id="squawk-statuses"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Squawk Statuses</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Define the different statuses a sqauwk can be assigned.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {squawkStatuses?.filter((s: any) => s?.id)?.length > 0 ? (
            <DataTable
              selectedRowId={false}
              canDrag={false}
              showTotalEntries={false}
              isSorted={false}
              canSearch={false}
              entriesPerPage={false}
              table={{
                columns: tableColumns,
                rows: squawkStatuses?.filter((s: any) => s?.id) || [],
              }}
            />
          ) : (
            <SoftTypography variant="caption" color="text">
              <i>No squawk statuses have been added yet...</i>
            </SoftTypography>
          )}
        </SoftBox>
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              onClick={() => setOpenAddSquawkStatusModal(true)}
            >
              Add New Status
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddSquawkStatusModal}
        onClose={handleAddSquawkStatusModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddSquawkStatusModal
            handleClose={handleAddSquawkStatusModalClose}
            status={selectedStatus}
          />
        </SoftBox>
      </Modal>
    </Card>
  );
}

export default SquawkStatuses;
